.mobileContent {
  width: 100%;
  #miniprogram-header {
    background: url("https://oss.huizustore.com/66ded2b28c144fe5b9ee5faf10dc8409.png");
    background-size: 100% 100%;
    .mobileContent-container-header-top {
      margin-top: 2rem;
    }
  }
  #need {
    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 2.2rem;
      width: 40rem;
      margin: 0 auto;
    }
    .need-item {
      // position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 8rem;
      &-img {
        width: 4.72rem;
        height: 4.17rem;
        margin-bottom: 1.17rem;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.33rem;
        color: #333333;
        line-height: 1.83rem;
        // position: absolute;
        // white-space: nowrap;
      }
    }
  }
  #self {
    background-color: #F9F8F8;
    .self-img {
      width: 37.78rem;
      height: 24.28rem;
      margin: 2.22rem 0 1.11rem 0;
    }
    .self-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.33rem;
      color: #333333;
      line-height: 1.83rem;
      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 3.33rem;
        color: #ED0000;
        line-height: 4.67rem;
      }
    }
  }
  #solve {
    .container {
      display: flex;
      flex-direction: column;
      gap: 1.33rem;
    }
    .solve-item {
      display: flex;
      text-align: start;
      background-color: #F9F8F8;
      border-radius: 0.67rem;
      &-img {
        width: 11.11rem;
        height: 11.11rem;
      }
      &-detail{
        padding: 1.33rem 1.56rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.56rem;
          color: #333333;
          line-height: 2.22rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.33rem;
          color: #666666;
          line-height: 2rem;
        }
      }
    }
  }

  #solve-main {
    padding-left: 0;
    padding-right: 0;
    .container {
      display: flex;
      gap: 1.33rem;
      width: 41.33rem;
      overflow: scroll;
      margin: 0 auto;
    }
    .solve-main-item {
      background-color: #F9F8F8;
      text-align: start;
      border-radius: 0.67rem;
      &-img {
        width: 25.06rem;
        height: 14.89rem;
      }
      &-detail {
        padding: 1.61rem 1.5rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.56rem;
          color: #333333;
          line-height: 2.22rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.33rem;
          color: #666666;
          line-height: 2rem;
        }
      }
    }
  }
}

@primary-color: #0099ff;@font-size-base: 14px;