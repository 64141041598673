.newsCenter {
    width: 100%;
    height: 94rem;
    background: url("https://oss.huizustore.com/1f3359b759384afea5031bf77e39489f.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &-banner {
        width: 100%;
        height: 19rem;
        overflow: hidden;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            margin-top: 6.3rem;
            font-size: 2.56rem;
            color: #333333;
            line-height: 3.61rem; 
            text-align: center;    
            border: 0.48rem;     
        }
        &-input {
            padding: 1rem 2rem;
            background-color: #FFF;
            display: flex;
            width: 35.78rem;
            height: 3.98rem;
            margin: 1rem auto;
            .icon {
                width: 1.67rem;
                height: 1.67rem;
                background: url("https://oss.huizustore.com/ac0998219ac34f959c4df01f136c3a03.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;

            }
            .content {
                margin-top: -0.6rem;
                margin-left: 0.9rem;
                .ant-input-element {
    font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 1.27rem;
color: #999999;
line-height: 1.75rem;
padding: 0.2rem;

}
            }
        }
    }
    &-newsTabs {
       width:100%;
       --active-line-color: #ED0000;
       --active-title-color: #ED0000;
       --adm-color-border: none;
    }
    &-container {
        width: fit-content;
        margin: 0 auto;
        height: 64rem;
        overflow-x: auto;
        &-content {
            width: 37.67rem;
            height: 8.11rem;
            margin: 2.17rem 0;
            display: flex;
            justify-content: space-between;
            &-img {
                width: 12rem;
                height: 8rem;
                img {
                    width: 12rem;
                    height: 8rem;
                }
            }
            &-info {
                width: 23.78rem;
                height: 8.11rem;
                position: relative;
                &-title {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 1.78rem;
                    color: #333333;
                    line-height: 2.5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                &-date {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 1.17rem;
                    color: #999999;
                    line-height: 1rem;
                }
            }
        }
    }
    .line {
        height: 1px;
        width: 37.67rem;
        background-color: #F2F2F2;
    }
}

.newsPage {
    display: inline-block;
    margin: 0 0 0 50%;
    transform: translate(-50%,0);
    .ant-pagination-item {
        width: 4.25rem;
        height: 4.25rem;
        background: unset;
        border: 0px;
        // box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
        border-radius: 100%;
        a {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #282828;
            line-height: 4.25rem;
        }
    }
    .ant-pagination-item-active {
        box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
        background: #FFFFFF;
        a {
            color: #585858;
        }
    }
    .ant-pagination-prev {
        .ant-pagination-item-link {
            border: 0px;
            background: unset;
            path {
                d:path("M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z")!important
            }
        }
    }
    .ant-pagination-next {
        .ant-pagination-item-link {
            border: 0px;
            background: unset;
            path {
                d:path("M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z")!important
            }
        }
    }
}
@primary-color: #0099ff;@font-size-base: 14px;