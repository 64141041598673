.navContainer{
  position: relative;
  z-index: 999;
  height: 60px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .info {
    margin-top: 82px;
    height: 90px;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    .info-center{
      display: inline-flex;
      margin: 0 0 0 50%;
      transform: translateX(-50%);
      .news-container{
        display: flex;
        .news-card{
          width: 263px;
          height: 89px;
          padding: 19px 18px 18px;
          .news-title{
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 17px;
          }
          .news-content{
            margin-top: 6px;
            width: 227px;
            height: 30px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 15px;
          }
        }
        .news-line{
          width: 1px;
          height: 47px;
          background: #FFFFFF;
          opacity: 0.3;
          margin: 21px 0;
        }
      }
    }
  }
  .nav-div{
    width: 1440px;
    height: 60px;
    margin: 0 auto;
    background: #FFFFFF;  
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .nav-icon{
      display: inline-block;
      margin-left: 145px;
      margin-bottom: -10px;
      line-height: 78px;
      width: 80px;
      height: 30px;
      overflow: hidden;
      background: url('../../../官网切图/首页/深色底LOGO.png');
      background-size: 80px 30px;
      background-repeat: no-repeat;
    }
    .name{
      display: inline-block;
      line-height: 78px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
    }
    .nav-icon-font{
      display: inline-block;
      margin-left: 12px;
      line-height: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
    }
    .nav-font{
      &:hover{
        color: #EB3A32;
      }
      display: inline-block;
      margin-left: 84px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #282828;
      line-height: 60px;
      cursor: pointer;
    }
    .nav-fonts{
      margin-left: 44px;
      display: inline-block;
      line-height: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      cursor: pointer;
      &:hover{
        color: #EB3A32;
      }
    }
    .nav-button{
      margin-left: 44px;
      display: inline-block;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #EB3A32;
      border: 1px solid #EB3A32;
    }
    .hover-box{
      &:hover{
        .nav-title{
          display: block;
        }
      }
      .nav-fonts-hover{
        &:hover{
          color: #EB3A32;
          // .border-line{
          //   display: block;
          // }
        }
        margin-left: 44px;
        display: inline-block;
        line-height: 60px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        cursor: pointer;
      }
    }
    .nav-title{
      display: none;
      position: absolute;
      top: 60px;
      width: 420px;
      height: 180.5px;
      background: #FFFFFF;
      z-index: 2;
      .title-left{
        float: left;
        margin-left: 24px;
        .title{
          margin-top: 20px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333;
          line-height: 21px;
          border-bottom: 1px solid #DFDFDF;
          padding-bottom: 5px;
        }
        .content{
          display: block;
          &:hover{
            color: #EB3A32;
          }
          cursor: pointer;
          margin-top: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #333;
          line-height: 17px;
        }
      }
      .title-right{
        float: left;
        .title{
          display: inline-block;
          padding-bottom: 5px;
          margin-left: 30px;
          margin-top: 20px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333;
          line-height: 21px;
          border-bottom: 1px solid #DFDFDF;
        }
        .content{
          margin-left: 30px;
          .left{
            float: left;
            .fonts{
              display: block;
              text-align: start;
              margin-top: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999;
              line-height: 17px;
            }
          }
          .center{
            float: left;
            margin-left: 58px;
            .fonts{
              display: block;
              text-align: start;
              margin-top: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999;
              line-height: 17px;
            }
          }
          .right{
            float: left;
            margin-left: 41px;
            .fonts{
              display: block;
              text-align: start;
              margin-top: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999;
              line-height: 17px;
            }
          }
        }
      }
    }
  }

  .top-title{
    margin-left: 195px;
    margin-top: 82.5px;
    font-size: 48px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 72px;
  }
  .top-content-top{
    margin-left: 195px;
    margin-top: 18px;
    // width: 417px;
    font-size: 30px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 44px;
  }
  .top-content-center{
    margin-left: 195px;
    margin-top: 18px;
    // width: 336px;
    height: 19px;
    font-size: 19px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 19px
  }
  .top-content-bottom{
    margin-left: 195px;
    margin-top: 30px;
    width: 210px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 2px 4px 0px #315ECE;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    // filter: blur(9px);
    text-align: center;
    line-height: 66px;
    font-size: 36px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    a{
      color: #FFFFFF;
    }
  }
  .pic-left{
    position: absolute;
    width: 282px;
    height: 96px;
    background: url('../../../官网切图/首页/banner山.png');
    background-size: 100% 100%;
    left: 40px;
    bottom: 48px;
  }
  .pic-right{
    position: absolute;
    width: 460px;
    height: 408px;
    background: url('../../../官网切图/首页/banner 主图.png');
    background-size: 100% 100%;
    top: 98px;
    bottom: 94px;
    right: 130px;
  }
  .modalReport{
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: #fff;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #999;
      }
    } 
    .ant-modal-header{
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
    }
    .ant-modal-title {
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
    }
    .ant-modal-body{
      // padding-top: 12px;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #111;
        margin-top: 38px;
        // line-height: 40.5px;
      }
      .icon{
        text-align: center;
        margin: 0 auto;
        width: 300px;
        // height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        .icon-pic{
          margin: 0 auto;
          width: 240px;
          height: 240px;
          background-image: url('https://oss.huizustore.com/a5b20735463c4338be23f6f2c55ea2c2.png');
          background-size: 100% 100%;
        }
        .icon-title{
          margin-top: 4px;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 400;
          color: #282828;
          line-height: 31.5px;
        }
      }
      .icon-tip{
        margin-top: 14px;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 400;
        color: #999;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  
  .modalRevice{
    .ant-modal-content{
      margin: 0 auto;
      width: 930px;
      height: 446px;
      box-shadow: 0px 0px 3px 3px rgba(59, 111, 239, 0.1);
      background: #FFF;
      border-radius: 6px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #999;
      }
      .background{
        .title{
          font-size: 27px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #111;
          line-height: 40.5px;
          text-align: center;
        }
        .icons{
          margin: 0 auto;
          width: 876px;
          height: 270px;
          margin-left: 26px;
          margin-top: 24px;
          .icon-left{
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .left-pic{
              width: 192px;
              height: 192px;
              margin-left: 24px;
              margin-top: 18px;
              background-image: url("https://oss.huizustore.com/61704635c84a43ab835eb74f88552909.png");
              background-size: 100% 100%;
            }
            .left-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
            }
          }
          .icon-center{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .center-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/安卓.png");
              background-size: 100% 100%;
            }
            .center-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
            }
          }
          .icon-right{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .right-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/IOS.png");
              background-size: 100% 100%;
            }
            .right-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
              .tip-small{
                margin-top: -10px;
                font-size: 10px;
              }
            }
          }
        }
      }
    } 
  }
  .modalLease{
    .ant-modal-content{
      margin: 0 auto;
      width: 660px;
      height: 770px;
      box-shadow: 0px 0px 4px 4px rgba(57, 59, 65, 0.1);
      background: #FFF;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #999;
      }
      .modalContainer{
        .title{
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #111;
          line-height: 36px;
          text-align: center;
        }
        .title-top{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #111;
          line-height: 27px;
        }
        .icons{
          margin-left: 18px;
          .iconTop{
            height: 90px;
            margin-top: 12px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              border: 2px solid #F9F8F8;
              cursor: pointer;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_bijiben @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              border: 2px solid #F9F8F8;
              margin-left: 18px;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_taishiji @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #F9F8F8;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_dayinji @2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
          }
          .iconBottom{
            height: 90px;
            margin-top: 18px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              border: 2px solid #F9F8F8;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_shouji @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #F9F8F8;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_touyingyi @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #F9F8F8;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #F9F8F8;

              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_其他服务@2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
          }
        }
        .title-center{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #111;
          line-height: 27px;
        }
        .textArea{
          margin-left: 18px;
          margin-top: 12px;
          width: 576px;
          height: 198px;
          background: #F9F8F8;
          border-radius: 8px;
          border: 2px solid #F9F8F8;
          overflow: hidden;
          .TextArea{
            .ant-input{
              min-height: 170px;
              resize: none;
              &::-webkit-input-placeholder{
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333;
                line-height: 24px;
                letter-spacing: 2px;
                padding: 8px 0 0 8px;
              }
            }
          }
        }
        .form{
          margin-left: 18px;
          margin-top: 30px;
          .title-bottom{
            float: left;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #111;
            line-height: 36px;
          }
          .input{
            float: left;
            width: 300px;
            height: 36px;
            background: #F9F8F8;
            border-radius: 8px;
            border: 2px solid #F9F8F8;
            overflow: hidden;
            .input-child{
              border: 0px;
              outline: none;
              width: 100%;
              height: 100%;
              background: #F9F8F8;
              &::-webkit-input-placeholder{
                font-size: 16.5px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #111;
                line-height: 33px;
              }
            }
          }
        }
        .button{
          margin-top: 6px;
          margin-left: 50%;
          transform: translateX(-50%);
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 27px;
          text-align: center;
          background: #ED0000;
          border-radius: 8px;
          padding: 12px 24px;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
  



@primary-color: #0099ff;@font-size-base: 14px;