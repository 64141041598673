.header {
    &-top {
       // width: 375px;
        width: 100%;
        height: 75px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        &-icon {
            width: 100px;
            height: 38px;
            background: red;
            margin: 19px;
            background: url('../../../官网切图/首页/LOGO-mobile.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        &-hamburger {
            width: 28px;
            height: 28px;
           // background: #000;
            background-image: url("../../../官网切图/首页/align-right-outlined.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 23px 19px;
        }
        &-Popup {
            .adm-popup-body {
                overflow-x: auto;
            }
            .adm-mask {
                opacity: 0;
            }
            .adm-popup-body-position-left {
                background: #fff !important;
                .adm-popup-close-icon {
                    margin: 15px;
                }
            }

            &-title{
                margin: 28px 28px 0;
                width: 133px;
                height: 50px;
                background: url("https://oss.huizustore.com/97331d0b5ad845ad96cb92a1bb3d3c1d.png");
            }
            &-fold {
                .header-top-Popup-fold .adm-collapse-panel-content .adm-list-item-conten {
                    margin-bottom: 1rem;
                }
                .adm-collapse-panel {
                    &-header {
                        .adm-list-item-content {
                                border-top: 1px solid rgba(255, 255, 255, 0.08);
                        }
                    }
                    &-content {
                        .adm-list-item-content {
                            border-top: 1px solid rgba(255, 255, 255, 0);
                            height: unset;
                            // margin-bottom: 1rem;
                            &-main {
                                padding: 0;
                                margin-bottom: 1rem;
                            }
                            &-arrow {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
                .adm-list {
                    margin-top: 16px;
                    ::after {
                        border-style: none!important;
                    }
                    &-item {
                        padding-left: 0;
                        background: rgba(0,0,0,0)!important;
                        &-content {
                            background: rgba(0,0,0,0);
                           // width: 375px;
                            width:100%;
                            height: 84px;
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333;
                            line-height: 28px;
                            padding: 0 28px;
                        }
                    }
                    &-body {
                        background: rgba(0,0,0,0);
                        border-top: 1px solid rgba(255, 255, 255, 0.08);
                        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                    }
                }
                &-item {
                    &-sub {
                        .adm-list-item-content {
                            padding-right: 0 !important;
                        }
                        .adm-collapse-panel-content-inner {
                            height: 100px !important;
                        }
                    }
                    .adm-list-item-content {
                        height: 64px;
                        &:hover{
                            color: #333;
                          }
                        color: #333;
                    }
                }
                &-consumer {
                    margin: 28px 0 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    &-box {
                        margin-bottom: 28px;
                        &-tip {
                            height: 25px;
                            margin-top: 9px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333;
                            line-height: 25px;     
                            text-align: center;                       
                        }
                        &-subTip {
                            height: 19px;
                            margin-top: 2px;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333;
                            line-height: 19px;      
                            text-align: center;                      
                        }
                        &-iconFir {
                            width: 150px;
                            height: 150px;
                            margin: 0 auto;
                            background-image: url(../../../官网切图/二维码/支付宝小程序.jpeg);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                        &-iconSec {
                            width: 150px;
                            height: 150px;
                            margin: 0 auto;
                            background-image: url(../../../官网切图/二维码/安卓.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                        &-iconThi {
                            width: 150px;
                            height: 150px;
                            margin: 0 auto;
                            background-image: url(../../../官网切图/二维码/IOS.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                    }
                }
                &-cloudconsumer {
                    margin: 28px 0 28px 0;
                    &-title{
                        font-size: 17px;
                        color:#666;
                    }
                    a{
                        text-decoration:none !important;
                        color: #333 !important;
                    }
                    &-content{  
                        margin:0 0 28px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        white-space: wrap;
                        &-item{
                            flex-grow: 1,1,1;
                            color: #333;
                            font-size: 18px;
                            font-family: PingFangSC-Regular;
                            white-space: wrap;
                            flex-direction:column;
                            justify-content: space-between;
                            span{
                                text-align:left;
                                display:inline-block;

                            }
                        }
                    }

                }
               
            }
            &-button {
                &:hover{
                    color: #999;
                    
                  }
                background: rgba(0,0,0,0);
                display: flex;
                align-items: center;
                width: 200%;
                height: 64px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333;
                line-height: 84px;
                padding: 0 28px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                a {
                    color: #333;
                    &:hover{
                        color: #FFFFFF;
                      }
                }
            }
        }
    }
}
.adm-center-popup{
    .adm-center-popup-body {
        border: 0.7px solid rgba(255, 255, 255, 1);
        border-radius: 16px;
        background-color:rgba(255,255,255,0.7) !important; 
        .adm-modal-footer{
            padding:8px 70px 15px;
        }
        button{
            --background-color:rgba(255, 7, 7, 0.9);
            --border-color: none;
            border-radius: 5px;
        }
        .adm-modal-title{
            margin: 20px auto 3px;
            font-size: 25px;
            letter-spacing: 1px;
            //font-weight: unset;
            font-family: PingFangSC-Regular;
        }
    }
}
.adm-modal-content::-webkit-scrollbar{
    display: none !important;
}
.adm-list-default .adm-list-body{
    border:none;
}
.adm-list-item {
    background:transparent;
    padding:0;
}

.adm-list-item-content{
    border-top: none;
    padding:0;
}
.adm-list-item-content .adm-form-item-child-inner{
    background:transparent;
   // border: 1px solid rgba(255, 255, 255, 1);
   // border-radius: 5px;
   // background-color:rgba(255,255,255,0.3)
}
.header-modal {
    .adm-center-popup-wrap {
    background-color: #fff;
    border-radius: 0.78rem;
    height: 70rem;
    }
}

.form{  
    // background: #fff;
    border:none;
    // .adm-list-body{
    //    // border: 1px solid rgba(255, 255, 255, 1);
    //     border-radius: 5px;
    //   //  background-color:rgba(255,255,255,0.3) !important; 
    // }
    .adm-list-header{
        color:black
    }
    .adm-list-header {
        font-weight: bold;
        padding:7px 0 7px 0;
    }
    &-content{
        // .form-content-top .form-content-bottom{
        //     display:flex;
        //     justify-content: space-between;
        //     align-items:baseline;
        // }
        .form-conten-box{
            width:256px;
            margin-left: -12px;
            text-align: center;
            overflow:hidden;
            //阴影效果
            box-shadow: 0px 0px 10px 0px rgba(43,2,1,0.3);
            //box-shadow: 0 0 10px spread rgba(0, 0, 0, .2);
        }
        &-bottom{
            display:flex;
            margin:10px auto;
            &-phone{
                flex:1;
            }
        }
        &-title{
            font-weight: bold;
            padding:9px 0 9px 0;
            
        }
        
        .adm-text-area{
            padding:2px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 10px;
            background-color:rgba(255,255,255,0.3) ; 
            &-element:placeholder-shown{
                color:#232323 !important;
                padding: 7px;
                word-spacing: normal;
            }
            &-element{
                height: 200px;

            }
            --placeholder-color:rgba(255, 255, 255, 0.7)!important;
            --font-size:14px;
            
        }
        &-tunk{
            display:flex;
            flex-wrap: wrap;
            width:280px;
            &-box{
                // background: #000;
                display: flex;
                flex-wrap: nowrap;
                height:40px;
                width:82px;
                border-radius: 5px;
                line-height: 40px;
                background: #F9F8F8;
                margin:2px;
               // margin: 3px 2px 3px 2px;
                img{
                    margin:5px;
                    width:20px;
                    height:20px; 
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                &-item{
                    flex:1;
                    font-size:5px;
                }
            
            }
            .box-hover{
                background-color: rgb(247, 215, 215);
                border:1.4px solid rgb(207, 71, 71);
            }
        }
       
        .adm-input-element{
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 5px;
            background-color:rgba(255,255,255,0.3) !important; 
            padding: 3px 5px 3px 5px;
            --font-size:14px;
            --placeholder-color:rgba(99, 97, 97, 0.5)!important;
            --placeholder-color:rgba(255, 255, 255, 0.7)!important;
        }
        .adm-text-area-element::placeholder {
            color: #999 !important;

        }
    }  

    
}
.modalform{
    &-icon{
        &-pic{
    
            width: 200px;
            height: 200px;
            margin: 20px auto 15px;
            background-image: url(../../../官网切图/二维码/风控报告.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        &-title{
            text-align: center;
            font-size:23px;
            font-family: PingFangSC-Regular;
        }
    }
    &-tip{
        margin-top:5px;
        text-align: center;
        font-size: 19px;
        letter-spacing: 1px;
        font-family: PingFangSC-Regular;
    }
}



@primary-color: #0099ff;@font-size-base: 14px;