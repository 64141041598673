.indexContent{
  min-width: 1440px;
  position: relative;
  background-color: #F9F8F8;
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .swiper-dots {
    li {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #E8E8E8;
      button {
        width: 5px;
        height: 5px;
        background-color: #E8E8E8;
        border-radius: 50%;
      }
    }
    .slick-active {
      width: 5px !important;
      height: 5px;
      border-radius: 50%;
      background-color: #ED0000;
      button {
        width: 5px;
        height: 5px;
        background-color: #ED0000 !important;
        border-radius: 50%;
      }
    }
  }
  .modalLease{
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: #FFF;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
    } 
    .ant-modal-header{
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
    }
    .ant-modal-title {
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #111;
      line-height: 30px;
    }
    .ant-modal-body{
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #111;
        margin-bottom: 18px;
      }
      .icon{
        text-align: center;
        margin: 0 auto;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        padding-top: 30px;
        .icon-pic{
          margin: 0 auto;
          width: 240px;
          height: 240px;
          background-image: url('../../官网切图/首页/消费者业务二维码.png');
          background-size: 100% 100%;
        }
        .icon-title{
          margin-top: 10px;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          line-height: 31.5px;
        }
      }
    }
  
  }


  .container1{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    // align-items: center;
    text-align: center;
    background-color: #FFF;
    // 数据展示部分
    .data-title{
      margin-top: 91px;
      width: 1050px;
      height: 296px;
      border-radius: 9px;
      // background-image: url('../../官网切图/首页/顶部数据展示装饰.png');
      background-size: 100% 100%;
      .data-time{
        margin-top: 30px;
        height: 44px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        line-height: 29px;
      }
      .hr{
        margin-top: 15px;
        border: 1px dashed #666666;
        visibility: hidden;
      }
      .data-datas{
        margin-left: 57px;
        display: flex;
        justify-content: center;
        .data-data{
          // float: left;
          margin-top: 20px;
          margin-left: 83px;
          // background: #ECF1FF;
          border-radius: 50%;
          .data-span{
            text-align: start;
            .data-span-font{
              padding-top: 45px;
              font-size: 55px;
              font-family: Oswald ,PingFangSC-Medium, PingFang SC;
              font-weight: 900;
              color: #ED0000;
              line-height: 65px;
            }
            .data-span-unit {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 17px;
              color: #ED0000;
              line-height: 25px;
            }
            .data-span1{
              display: block;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  //核心产品部分
  .product{
    padding: 90px 0;
    // width: 1440px;
    text-align: center;
    background: #F9F8F8;
    .product-title{
      font-family: PingFangSC,PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333;
      line-height: 44px;
    }
    .hr-orange{
      width: 60px;
      height: 2px;
      background: #EF983B;
      border: 0px;
    }
    .product-title-bottom{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
    }
    .product-icon{
      padding-top:48px ;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      column-gap: 18px;
      .bussiness {
        background: url("https://oss.huizustore.com/12f9673b16fe47d1a035638fc0015fda.png");
        background-size: 100% 100%;
      }
      .bussiness-active {
        background: url("https://oss.huizustore.com/ca95003059004c0eadd74aba79bea01d.png");
        background-size: 100% 100%;
        box-shadow: 0 15px 20px rgba(#ED0000, 0.2);
        border-radius: 50%;
      }
      .mini {
        background: url("https://oss.huizustore.com/f930fbc9812c432da7e1fcba49ae23f2.png");
        background-size: 100% 100%;        
      }
      .mini-active {
        background: url("https://oss.huizustore.com/72cabfeb182642a7b89de5c419da61a1.png");
        background-size: 100% 100%;
        box-shadow: 0 15px 20px rgba(#ED0000, 0.2);
        border-radius: 50%;
      }
      .open {
        background: url("https://oss.huizustore.com/9665ebd659654247a922834d8b959b7a.png");
        background-size: 100% 100%;
      }
      .open-active {
        background: url("https://oss.huizustore.com/6ff598220bd94b0093f9355c1e9f1c84.png");
        background-size: 100% 100%;
        box-shadow: 0 15px 20px rgba(#ED0000, 0.2);
        border-radius: 50%;
      }
    
      &-box {
        width: 338px;
        height: 201px;
        background: #FFF;
        border-radius: 12px;
        cursor: pointer;
        &-icon {
          width: 106px;
          height: 106px;
          margin: 0 auto;
          margin-top: 40px;
        }
        &-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
          margin-top: 23px;
        }
      }
    }
    #storeContent {
      background: url("https://oss.huizustore.com/c3bf6fda7ad040bb988d30b94b83d707.png");
      background-size: 100% 100%;
    }
    #appContent{
      background: url("https://oss.huizustore.com/fe9d8e5a162146379fd3ba84844ef103.png");
      background-size: 100% 100%;
    }
    #openContent{
      background: url("https://oss.huizustore.com/fc3d4d0337aa4d83a1b58ced14238356.png");
      background-size: 100% 100%;
    }

    .product-content-store{
      margin: 0 auto;
      margin-top: 15px;
      width: 1050px;
      height: 335px;
      background: #FFFFFF;
      border-radius: 10px;
      text-align: left;
      .product-content-info {
        padding: 60px 0 0 40px;
      }
      .product-content-title{
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333;
        line-height: 35px;
      }
      .product-contents{
        li{
          margin-top: 7px;
          font-size: 12px;
          font-family: AppleSystemUIFont;
          color: #999;
          line-height: 17px;
          display: flex;
          align-items: center;
          column-gap: 8px;
          .orangeCircle{
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: red ;
          }
        }
      }
      .product-button{
        margin-top: 27px;
        .product-button-left{
          float: left;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 27px;
          padding: 8px 24px;
          background-color: #ED0000;
          border-radius: 6px;
          display: inline-block;
          cursor: pointer;
          a{
            color: #FFFFFF;
          }
        }
        .product-button-right{
          float: left;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 18px;
          color: #EB3A32;
          line-height: 27px;
          padding: 8px 24px;
          border-radius: 6px;
          display: inline-block;
          cursor: pointer;
          border: 1px solid #EB3A32;
          margin-left: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .product-rightBox{
        .rightStore{
          margin-top: -259px;
          float: right;
          width: 500px;
          height: 341px;
          background-image: url('../../官网切图/首页/租赁店铺.png');
          background-size: 100% 100%;
        }
        .rightApp{
          margin-top: -226px;
          float: right;
          width: 500px;
          height: 341px;
          background-image: url('../../官网切图/首页/小程序.png');
          background-size: 100% 100%;
        }
        .rightPc{
          margin-top: -226px;
          float: right;
          width: 500px;
          height: 341px;
          background-image: url('../../官网切图/首页/PC独立站.png');
          background-size: 100% 100%;
        }
      }
    }
  }
  //四重保障部分
  .guarantee{
    text-align: center;
    background-color: #FFF;
    padding-top:90px ;
    height: 805px;
    .guarantee-title{
      font-family: PingFangSC,PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333;
      line-height: 44px;
    }
    .hr-orange{
      width: 60px;
      height: 2px;
      background: #EF983B;
      border: 0;
    }
    .guarantee-title-bottom{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
    }
    .guarantee-content{
      padding-top: 48px;
      height: 390px;
      width: 1050px;
      display: flex;
      padding-bottom: 80px;
      justify-content: center;
      column-gap: 16px;
      margin: 0 auto;
      .guarantee-item {
        width: 254px;
        height: fit-content;
        border-radius: 9px;
        background: #F9F8F8;
        &-img {
          width: 254px;
          height: 201px;
          border-radius: 9px 9px 0 0;
        }
        &-detail {
          padding: 26px 18px;
          text-align: start;
          &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
          }
          &-info {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 21px;
            margin-top: 14px;
            height: 105px;
          }
        }
      }
    }
  }
  //消费者业务
  .consumption{
    padding-top: 90px;
    padding-bottom: 90px;
    text-align: center;
    background-color: #FFF;
    .consumption-title{
      font-family: PingFangSC,PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333;
      line-height: 44px;
    }
    .hr-orange{
      width: 60px;
      height: 2px;
      background: #EF983B;
      border: 0;
    }
    .consumption-title-bottom{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin-bottom: 45px;
    }
    .consumption-content{
      display: flex;
      column-gap: 18px;
      justify-content: center;
      width: 1050px;
      margin: 0 auto;
      #video {
        background: url("https://oss.huizustore.com/9d91fc990bfd4fa18ffb6418e11a10f5.png");
        background-size: 100% 100%;
      }
      #brand {
        background: url("https://oss.huizustore.com/2f6788cf7ccf4c208b4d5f965b2dbbe2.png");
        background-size: 100% 100%;
      }
      #camera {
        background: url("https://oss.huizustore.com/467e81167cab413fb53743a1916052cd.png");
        background-size: 100% 100%;
      }
      #computer {
        background: url("https://oss.huizustore.com/abdbdda79fb04e8aa1afe5d20f7d9510.png");
        background-size: 100% 100%;
      }
      #watch {
        background: url("https://oss.huizustore.com/b41d57a4121949acad594bd869bdc0e0.png");
        background-size: 100% 100%;
      }
      #pad {
        background: url("https://oss.huizustore.com/f3980fc11a134ad2a6e30d9406a31540.png");
        background-size: 100% 100%;
      }
      .consumption-item {
        width: 173px;
        height: 319px;
        padding: 28px;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
        }
        &-btn {
          padding: 7px 32px;
          border-radius: 19px;
          border: 1px solid #333333;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .consumption-wide-content {
        width: 287px;
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        .consumption-wide-item {
          height: 150px;
          padding: 30px 18px;
          text-align: start;
          &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            margin-left: 6px;
          }
          &-btn {
            display: inline-block;
            padding: 5px 34px;
            border-radius: 19px;
            border: 1px solid #333333;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .consumption-button{
      margin: auto;
      margin-top: 36px;
      width: 180px;
      height: 60px;
      line-height: 60px;
      background: #EB3A32;
      border-radius: 6px;
      font-size: 21px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
    .consumption-footer {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin-top: 33px;
      position: relative;
      display: inline-block;
      &::before {
        display: inline-flex;
        content: '';
        height: 1px;
        width: 106px;
        background: #979797;
        vertical-align: middle;
        margin-right: 80px;
      }
      &::after {
        display: inline-flex;
        content: '';
        height: 1px;
        width: 106px;
        background: #979797;
        vertical-align: middle;
        margin-left: 80px;
      }
    }
    .consumption-footer-info {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 19px;
      margin-top: 10px;
    }
  }
  //联系我们部分
  .contact{
    margin: 0 auto;
    text-align: center;
    background-color: #F9F8F8;
    padding-bottom: 150px;
    .contact-title{
      margin-top: 90px;
      font-family: PingFangSC,PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333;
      line-height: 44px;
    }
    .contact-content{
      margin: 0 auto;
      margin-top: 57px;
      width: 1050px;
      height: 404px;
      background: #FFFFFF;
      border-radius: 8px;
      background-image: url("https://oss.huizustore.com/599238f6a6c141c08c7f5548ab0299b3.png");
      background-size: 100% 100%;
      position: relative;
      &-pop {
        position: absolute;
        width: 164px;
        top: 68px;
        left: 388px;
        text-align: start;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #111;
          line-height: 24px;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #393939;
          line-height: 22px;
        }
      }
      &-contact {
        position: absolute;
        left: 110px;
        bottom: 50px;
        &-icon {
          display: inline-block;
          width: 12px;
          height: 16px;
          background: url("https://oss.huizustore.com/8866b8d17691400ea2f9b3c16633b0fb.png");
          background-size: 100% 100%;
          margin-right: 8px;
        }
        &-phone {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 22px;
          color: #000000;
          line-height: 33px;
        }
        &-time {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 28px;
          margin: 0 8px;
        }
      }
      .contact-form{
        float: right;
        margin-right: 147px;
        text-align: center;
        .contact-form-title{
          margin-top: 30px;
          font-size: 21px;
          font-family: AppleSystemUIFont;
          font-weight: 500;
          color: #232323;
          line-height: 30px;
          text-align: start;
        }
        .form-input{
          width: 255px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #979797;
          overflow: hidden;
          .input-style{
            border: 0px;
            outline: none;
            width: 100%;
            height: 100%;
          }
        }
        .form-button{
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 27px;
          padding: 12px 24px;
          background-color: #ED0000;
          border-radius: 6px;
          display: inline-block;
          cursor: pointer;
          border: none;
        }
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;