.mobileContent {
  width: 100%;

  #control {
    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 2.4rem;
      justify-content: center;
    }
    .control-item {
      padding: 2rem 2.06rem;
      background-color: #F9F8F8;
      width: 18.17rem;
      height: 24.83rem;
      position: relative;
      text-align: start;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.78rem;
        color: #333333;
        line-height: 2.5rem;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #666666;
        line-height: 2rem;
        margin-top: 0.89rem;
      }
      &-btn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.56rem;
        color: #FFFFFF;
        line-height: 2.22rem;
        padding: 0.89rem 3.22rem;
        background-color: #ED0000;
        white-space: nowrap;
        border-radius: 0.78rem;
        display: inline-block;
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  #platform-header {
    background: url("https://oss.huizustore.com/bbf1278266f944e7a84ff85678e269b0.png");
    background-size: 100% 100%;
    width: 45.18rem;
    height: 37.35rem;
    margin: 0 auto;
    .mobileContent-container-header-top {
      margin-top: 3.65rem;
    }
  }
  
  #machine {
    background-color: #F9F8F8;
    .container {
      display: flex;
      gap: 1.33rem;
      width: 41.67rem;
      overflow: scroll;
      margin: 0 auto;
    }
    .machine-item {
      background-color: #fff;
      text-align: start;
      border-radius: 0.67rem;
      &-img {
        width: 25.06rem;
        height: 14.89rem;
      }
      &-detail {
        padding: 1.61rem 1.5rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.56rem;
          color: #333333;
          line-height: 2.22rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.33rem;
          color: #666666;
          line-height: 2rem;
        }
      }
    }
  }

  #more {
    .more-img {
      width: 37.67rem;
      height: 20.17rem;
    }
    .more-item {
      width: 37.67rem;
      padding: 1.72rem 1.44rem;
      text-align: start;
      border-bottom: 1px solid #E0E0E0;
      margin: 0 auto;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.56rem;
        color: #333333;
        line-height: 2.22rem;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #666666;
        line-height: 2rem;
      }
    }
    #none-border {
      border: none;
    }
  }
}

@primary-color: #0099ff;@font-size-base: 14px;