.enterprise {
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #FFEDED 0%, #fff 20%, #fff 100%);
    &-header {
    width: 45.18rem;
    height: 101.45rem;
    margin: 0 auto;
    background: url("https://oss.huizustore.com/09d5149c4b3a4cc4884c02311578228c.png");
    background-size: 100% 100%;
  }
  &-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 2.77rem;
    color: #333333;
    line-height: 3.92rem;
    padding-top: 6.87rem;
  }
  &-zfb {
    margin-top: 3.55rem;
    img {
      width: 19.28rem;
      height: 24.1rem;
    }
  }
  &-android {
    margin-top: 3.55rem;
    img {
      width: 15.66rem;
      height: 15.66rem;
    }
    div {
      margin-top: 1.2rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.69rem;
      color: #282828;
      line-height: 2.41rem;
    }
  }
  &-ios {
    margin-top: 3.55rem;
    img {
      width: 15.42rem;
      height: 15.42rem;
    }
    div {
      margin-top: 1.4rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.69rem;
      color: #282828;
      line-height: 2.41rem;
    }
  }

  .form{  
    border:none;
    background: transparent !important;
    &-content {
      background: transparent !important;

    }
    .adm-list {
      background: transparent !important;
    }
    .adm-list-header{
      color:black
    }
    .adm-list-header {
      font-weight: bold;
      padding:7px 0 7px 0;
    }
    &-content{
      .form-conten-box{
        width:256px;
        margin-left: -12px;
        text-align: center;
        overflow:hidden;
        //阴影效果
        box-shadow: 0px 0px 10px 0px rgba(43,2,1,0.3);
      }
      &-bottom{
        display:flex;
        margin: 0 auto;
        &-phone{
          flex:1;
        }
      }
      &-title{
        font-weight: bold;
        padding-left: 2.35rem;
        text-align: start;
      }
        
      .adm-text-area{
        width: 41.84rem;
        margin: 0 auto;
        background: red;
        padding:2px;
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 10px;
        background-color:rgba(255,255,255,0.3) ; 
        position: relative;
        &-element:placeholder-shown{
          color:#232323 !important;
          padding: 1rem;
          word-spacing: normal;
          background: #F9F8F8;
        }
        &-element{
          height: 17.47rem;
          background: #F9F8F8;
          padding: 1rem;

        }
        --placeholder-color:rgba(255, 255, 255, 0.7)!important;
        --font-size:14px;
        &-count {
          position: absolute;
          right: 1rem;
          bottom: 1rem;
        }
      }
      &-tunk{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.39rem;
        width:100%;
        &-box{
          display: flex;
          flex-wrap: nowrap;
          height:7.23rem;
          width:19.7rem;
          border-radius: 0.72rem;
          background: #F9F8F8;
          align-items: center;
          img{
            margin:5px;
            width:4.82rem;
            height:4.82rem; 
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          &-item{
            margin-left: 1.35rem;
            text-align: start;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 1.45rem;
            color: #333333;
            line-height: 1.99rem;
          }
            
        }
        .box-hover{
          background-color: rgb(247, 215, 215);
          border:1.4px solid rgb(207, 71, 71);
        }
      }
      .adm-input {
        width: 40.84rem !important;
        height: 3.86rem;
        margin: 0 auto;
      }   
      .adm-input-element{
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 5px;
        background: #F9F8F8 !important; 
        padding: 1rem;
        --font-size:14px;
        --placeholder-color:rgba(99, 97, 97, 0.5)!important;
        display: inline-block;
      }
      .adm-text-area-element::placeholder {
        color: #999 !important;
      }
      .form-conten-btn {
        margin-top: 5rem;
        .form-conten-box {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.69rem;
          color: #FFFFFF;
          line-height: 2.41rem;
          padding: 0;
          background-color: #ED0000;
          display: inline-block;
          margin: 0 auto;
          .adm-button {
            background-color: #ED0000;
            border: none;
            display: inline-block;
          }
        }
      }
    }  
  }
}
@primary-color: #0099ff;@font-size-base: 14px;