.mobileContent {
  width: 100%;
  &-container {
    background-color: #F9F8F8;
  }
  #lock-header {
    background: url("https://oss.huizustore.com/bb2e6ad644ef4884b5ad0088bd322e6c.png");
    background-size: 100% 100%;
    width: 45.18rem;
    height: 37.35rem;
    margin: 0 auto;
    .mobileContent-container-header-top {
      margin-top: 3.65rem;
    }
  }

  #service {
    .service-img {
      width: 40.84rem;
      height: 21.87rem;
    }
    .service-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.45rem;
      color: #666666;
      line-height: 2.17rem;
      margin: 2.41rem 0;
    }
    .service-btn {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.56rem;
      color: #FFFFFF;
      line-height: 2.22rem;
      padding: 0.89rem 3.22rem;
      background-color: #ED0000;
      white-space: nowrap;
      border-radius: 0.78rem;
      display: inline-block;
    }
  }

  #strength {
    background-color: #F9F8F8;
    .container{
      display: flex;
      flex-wrap: wrap;
      gap: 2.22rem;
      justify-content: center;
    }
    .shop-item {
      height: 5.89rem;
      display: flex;
      text-align: start;
      width: 40.66rem;
      &-img {
        width: 5.89rem;
        height: 5.89rem;
      }
      &-detail{
        padding: 0.61rem 2rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.78rem;
          color: #333333;
          line-height: 2.5rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.33rem;
          color: #666666;
          line-height: 1.83rem;
        }
      }
    }
  }

  #flow {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .line {
      position: absolute;
      top: 0;
      left: 28%;
      width: 4rem;
      display: flex;
      justify-content: center;
      .line-main {
        width: 0.12rem;
        height: 46rem;
        background: url("https://oss.huizustore.com/6eefa285a3c84de686e57877840f6d3b.png");
        background-size: 100% 100%;
        display: flex;

      }
      &-item {
        width: 1.27rem;
        height: 1.27rem;
        background: url("https://oss.huizustore.com/3e1b476fe3ce4e01b4c64b2c6062be28.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        span {
          width: 50%;
          height: 50%;
          background-color: #ED0000;
          display: block;
          border-radius: 50%;
        }
      }
      #one {
        top: 10%;
      }
      #two {
        top: 30%;
      }
      #three {
        top: 50%;
      }
      #four {
        top: 70%;
      }
      #five {
        top: 90%;
      }
    }
    .flow-item-list {
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 1rem;
      .flow-item {
        display: flex;
        width: 32rem;
        align-items: center;
        justify-content: space-between;
        .number {
          font-family: AirbnbCerealApp, AirbnbCerealApp;
          font-weight: 300;
          font-size: 6.02rem;
          color: #ED0000;
          line-height: 7.83rem;
          width: 12rem;
        }
        div {
          text-align: start;
          width: 20rem;
        }
      }
      strong {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.93rem;
        color: #333333;
        line-height: 2.71rem;
        display: block;
        text-align: start;
      }
      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.45rem;
        color: #666666;
        line-height: 1.69rem;
        text-align: start;
      }
    }
  }

  #operate {
    background-color: #F9F8F8;
    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.70rem;
      justify-content: center;
    }
    .operate-item {
      padding: 2rem 0.96rem;
      background-color: #fff;
      width: 12.35rem;
      height: 23.92rem;
      position: relative;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.78rem;
        color: #333333;
        line-height: 2.5rem;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #666666;
        line-height: 2rem;
        margin-top: 0.89rem;
        text-align: start;
      }
      &-icon {
        margin: 0 auto;
        margin-bottom: 1.2rem;
      }
      #lock-img {
        width: 3.49rem;
        height: 4.7rem;
      }
      #unlock-img {
        width: 3.55rem;
        height: 4.7rem;
      }
      #free-img {
        width: 4.88rem;
        height: 4.7rem;
      }
    }
  }
}

@primary-color: #0099ff;@font-size-base: 14px;