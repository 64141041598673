.customer {
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #FFEDED 0%, #fff 20%, #fff 100%);

  &-header {
    width: 45.18rem;
    height: 101.45rem;
    margin: 0 auto;
    background: url("https://oss.huizustore.com/09d5149c4b3a4cc4884c02311578228c.png");
    background-size: 100% 100%;
  }
  &-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 2.77rem;
    color: #333333;
    line-height: 3.92rem;
    padding-top: 6.87rem;
  }
  &-zfb {
    margin-top: 3.55rem;
    img {
      width: 19.28rem;
      height: 24.1rem;
    }
  }
  &-android {
    margin-top: 3.55rem;
    img {
      width: 15.66rem;
      height: 15.66rem;
    }
    div {
      margin-top: 1.2rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.69rem;
      color: #282828;
      line-height: 2.41rem;
    }
  }
  &-ios {
    margin-top: 3.55rem;
    img {
      width: 15.42rem;
      height: 15.42rem;
    }
    div {
      margin-top: 1.4rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.69rem;
      color: #282828;
      line-height: 2.41rem;
    }
  }


  .fixBox-mobile{
    z-index: 1;
    top: 100px;
    right: 12px;
    height: 300px;
    position: fixed;
    .box-right{
      .top{
        width: 84px;
        height: 91px;
        background-image: url("https://oss.huizustore.com/11d7962903c14ef387a52fa33e85eb4b.png");
        background-size: 100% 100%;
      }
      .box{
        padding: 0;
        margin-top: -20px;
        margin-left: 9px;
        width: 66px;
        height: 21px;
        border-radius: 3px;
        .tip{
          text-align: center;
          line-height: 21px;
          color: #ffffff;
          font-size: 12px;
        }
      }
      .lease{
        margin-top: 6px;
        width: 78px;
        height: 78px;
        background: red;
        border-radius: 12px 12px 0 0;
        padding-top: 15px;
        margin-left: 4px;
        .pic{
          margin-left: 24px;
          width: 30px;
          height: 30px;
          background-image: url("https://oss.huizustore.com/0d84ebd1024242289c72a93e4ecd967d.png");
          background-size: 100% 100%;
        }
        .font{
          text-align: center;
          margin-top: 6px;
          font-size: 13.5px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }
        .box-lease{
          display: none;
          position: fixed;
          right: 9rem;
          top: 19rem;
          width: 23.31rem;
          height: 26.33rem;
          background: url("https://oss.huizustore.com/cc55acda5aaa4486b4347f1cc41b8c39.png");
          background-size: 100% 100%;
          border-radius: 8px;
          text-align: center;
          .title{
              margin-top: 3rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 1.93rem;
            color: #333333;
            line-height: 2.71rem;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
          .pic{
              margin: 0 auto;
            // margin-top: 1rem;
            width: 14rem;
            height: 14rem;
            background-image: url("https://oss.huizustore.com/c44b255b70f84f4d85c0fad047b54f68.png");
            background-size: 100% 100%;
          }
          .tip{
            margin-top: 1rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.27rem;
            color: #333333;
            line-height: 1.75rem;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
        }
        cursor: pointer;
        &:hover{
          background: #EB3A32;
          .box-lease{
            display: block;
          }
        }
      }
      .switch{
        margin-bottom: -156px;
        width: 78px;
        height: 156px;
        background: red;
        box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
        border-radius: 0 0 12px 12px;
        backdrop-filter: blur(6px);
        margin-left: 4px;
      }
      .wechat{
        position: relative;
        width: 78px;
        height: 78px;
        border-radius: 5px;
        padding-top: 15px;
        z-index: 1;
        margin-left: 4px;
        .pic{
          margin-left: 24px;
          width: 30px;
          height: 30px;
          background-image: url("https://oss.huizustore.com/f543c09afbdf43e383cacd0290c2bd7b.png");
          background-size: 100% 100%;
        }
        .font{
          text-align: center;
          margin-top: 6px;
          font-size: 13.5px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }
        cursor: pointer;
        &:hover{
          background: #EB3A32;
          .box-wechat{
            display: block;
          }
        }
        .box-wechat{
          display: none;
          position: fixed;
          right: 9rem;
          top: 18rem;
          width: 23.31rem;
          height: 28.8rem;
          background: url("https://oss.huizustore.com/24f41177ff604f26bbbaf0826e36be3a.png");
          background-size: 100% 100%;
          border-radius: 1rem;
          .title{
            margin-top: 2.65rem;
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 1.93rem;
            color: #333333;
            line-height: 2.71rem;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
          .tip{
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.45rem;
            color: #333333;
            line-height: 1.99rem;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
          .pic{
            margin-top: 2rem;
            margin: 0 auto;
            width: 13.98rem;
            height: 13.98rem;
            background-image: url("https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png");
            background-size: 100% 100%;
          }
          .content{
            margin-top: 1rem;
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.27rem;
            color: #333333;
            line-height: 1.75rem;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
        }
      }
      .phone{
        position: relative;
        z-index: 1;
        // margin-top: 6px;
        width: 78px;
        height: 78px;
        // background: rgba(0, 0, 0, 0.7);
        border-radius: 5px;
        padding-top: 15px;
        margin-left: 4px;
        .pic{
          margin-left: 24px;
          width: 30px;
          height: 30px;
          background-image: url("https://oss.huizustore.com/6d2303eef0f6446b99b036941e4b4725.png");
          background-size: 100% 100%;
        }
        .font{
          text-align: center;
          margin-top: 6px;
          font-size: 13.5px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }
        &:hover{
          background: #EB3A32;
          .box-phone{
            display: block;
          }
        }
        cursor: pointer;
        .box-phone{
          display: none;
          position: fixed;
          right: 9rem;
          top: 36rem;
          width: 21.5rem;
          height: 12.28rem;
          background: url("https://oss.huizustore.com/65679e09572c44c48c1196430d623255.png");
          background-size: 100% 100%;
          padding: 2.66rem 0 0 4.58rem;
        .bottom {
            // width: 80%;
            background: transparent;
            text-align: start;
        }
          
          
        
        }
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;