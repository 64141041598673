.modalAppointment{
  backdrop-filter: blur(10px);
  .ant-modal-content{
    width: 570px;
    height: 417px;
    // background: #FFFFFF;
    border-radius: 8px;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #232323;
      line-height: 54px;
    }
    .form-div{
      width: 360px;
      margin-left: 88.5px;
      margin-top: 30px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #232323;
      height: 36px;
      // line-height: 36px;
      .span{
        float: left;
        margin-top: 6px;
        display: block;
        height: 25px;
        line-height: 25px;
      }
      .inputs{
        float: left;
        display: block;
        width: 255px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #979797;
        overflow: hidden;
        .input{
          outline: none;
          border: 0px;
          width: 100%;
          height: 100%;
        }
      }
    }
    .buttonBox{
      .button{
        margin-left: 201px;
        margin-top: 24px;
        width: 120px;
        height: 42px;
        background: #EB3A32;
        border-radius: 8px;
        line-height: 34px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        border: none;
        cursor: pointer;
      }
    }

  }
}
@primary-color: #0099ff;@font-size-base: 14px;