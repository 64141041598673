
.mobileContent {
  .homePage-swiper {
    background-color: #F9F8F8;
  }

  &-container {
    width:100%;
    text-align: center;
    &-header {
      width:100%;
      height: 34.5rem;
      position: relative;
      overflow: hidden;
      background: url("https://oss.huizustore.com/d4855e2f1bea428186b951554a5f1240.png");
      background-size: 100% 100%;
      padding-top: 16rem;
      &-top {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 2.56rem;
        color: #333333;
        line-height: 3.61rem;
      }
      &-center {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #666666;
        line-height: 1.83rem;
      }
      &-bottom {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.67rem;
        color: #FFFFFF;
        line-height: 2.33rem;
        padding: 1rem 4.2rem;
        background-color: #ED0000;
        border-radius: 0.67rem;
        display: inline-block;
        margin-top: 2.5rem;
        a {
          color: #FFF;
        }
      }
    }
  }
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  // 数据展示部分
  .container1{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 5rem auto;
    .data-title{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 2.33rem;
      color: #333333;
      line-height: 3.22rem;
      .data-time{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 2.33rem;
        color: #333333;
        line-height: 3.22rem;
        div {
          display: flex;
          column-gap: 1.33rem;
          justify-content: center;
          margin-top: 1rem;
        }
        .time {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.78rem;
          color: #333333;
          line-height: 2.5rem;
          display: inline-flex;
          column-gap: 0;
          padding: 0.11rem 0.44rem;
          background-color: #F9F8F8;
          border-radius: 0.22rem;
          margin-top: 0;
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1rem;
            color: #999999;
            margin-top: 0.2rem;
          }
        }

      }
      .data-datas{
        display: flex;
        flex-wrap: wrap;
        column-gap: 2rem;
        justify-content: center;
        #month {
          margin-right: 2rem;
        }
        #province {
          margin-right: 3rem;
        }
        .data-data{
          margin-top: 20px;
          border-radius: 50%;
          .data-span{
            text-align: start;
            .data-span-font{
              padding-top: 45px;
              font-size: 55px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 900;
              color: #ED0000;
              line-height: 65px;
            }
            .data-span-unit {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 17px;
              color: #ED0000;
              line-height: 25px;
            }
            .data-span1{
              display: block;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  // 消费者业务
  .consumption{
    margin-top: 4.82rem;
    padding-bottom: 4.82rem;
    text-align: center;
    .consumption-title{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 2.53rem;
      color: #232323;
      line-height: 3.49rem;
    }
    .hr-orange{
      width: 3.61rem;
      height: 0.36rem;
      background: #EF983B;
      border: 0;
    }
    .consumption-title-bottom{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.45rem;
      color: #666666;
      line-height: 1.99rem;
    }
    .consumption-content{
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 2.5rem;
      #video {
        background: url("https://oss.huizustore.com/9d91fc990bfd4fa18ffb6418e11a10f5.png");
        background-size: 100% 100%;
      }
      #brand {
        background: url("https://oss.huizustore.com/2f6788cf7ccf4c208b4d5f965b2dbbe2.png");
        background-size: 100% 100%;
      }
      #camera {
        background: url("https://oss.huizustore.com/467e81167cab413fb53743a1916052cd.png");
        background-size: 100% 100%;
      }
      #computer {
        background: url("https://oss.huizustore.com/abdbdda79fb04e8aa1afe5d20f7d9510.png");
        background-size: 100% 100%;
      }
      #watch {
        background: url("https://oss.huizustore.com/ac720a1975db40d68ac59d27be02b441.png");
        background-size: 100% 100%;
      }
      #pad {
        background: url("https://oss.huizustore.com/a2ccde6d727440258e6e2cb977878be9.png");
        background-size: 100% 100%;
      }
      .consumption-item {
        width: 13.86rem;
        height: 25.54rem;
        padding: 1.87rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.69rem;
          color: #333333;
          line-height: 2.41rem;
        }
        &-btn {
          padding: 0.36rem 2.59rem;
          border-radius: 1.14rem;
          border: 1px solid #333333;
          margin-top: 0.42rem;
          cursor: pointer;
          font-size: 1.08rem;
        }
      }
      .consumption-wide-content {
        width: 25.52rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: 1.4rem;
        .consumption-wide-item {
          width: 25.52rem;
          height: 12.05rem;
          padding: 1.87rem;
          text-align: start;
          &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 1.69rem;
            color: #333333;
            line-height: 2.41rem;
            margin-left: 0.42rem;
          }
          &-btn {
            display: inline-block;
            padding: 0.42rem 2.53rem;
            border-radius: 1.14rem;
            border: 1px solid #333333;
            margin-top: 0.42rem;
            cursor: pointer;
            font-size: 1.08rem;
          }
        }
      }
    }
    .consumption-button{
      margin: auto;
      margin-top: 36px;
      width: 180px;
      height: 60px;
      line-height: 60px;
      background: #EB3A32;
      border-radius: 6px;
      font-size: 21px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
    .consumption-footer {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.45rem;
      color: #666666;
      line-height: 1.99rem;
      margin-top: 3.6rem;
      position: relative;
      display: inline-block;
      &::before {
        display: inline-flex;
        content: '';
        height: 1px;
        width: 80px;
        background: #979797;
        vertical-align: middle;
        margin-right: 30px;
      }
      &::after {
        display: inline-flex;
        content: '';
        height: 1px;
        width: 8rem;
        background: #979797;
        vertical-align: middle;
        margin-left: 3rem;
      }
    }
    .consumption-footer-info {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.08rem;
      color: #666666;
      line-height: 1.51rem;
      margin-top: 1rem;
    }
  }
  .product{
    padding: 5rem 0;
    text-align: center;
    background: #F9F8F8;
    .product-title{
      font-size: 2.5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      line-height: 3.6rem;
    }
    .hr-orange{
      width: 60px;
      height: 2px;
      background: #EF983B;
      border: 0px;
    }
    .product-title-bottom{
      font-size: 1.5rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #393939;
      line-height: 2rem;
    }
    .product-icon{
      padding-top: 2.5rem ;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      column-gap: 1.3rem;
      .bussiness {
        background: url("https://oss.huizustore.com/e18cc5b2758d4d528ac46377b7eca605.png");
        background-size: 100% 100%;
      }
      .bussiness-active {
        background: url("https://oss.huizustore.com/22a19c42bfdd4323a7b125fa5fab2fe0.png");
        background-size: 100% 100%;
      }
      .mini {
        background: url("https://oss.huizustore.com/46d7a65a94b7481691777fe24b34fdd6.png");
        background-size: 100% 100%;
      }
      .mini-active {
        background: url("https://oss.huizustore.com/dba7a6c78cbb4ffb9d6111092f3f44a4.png");
        background-size: 100% 100%;
      }
      .open {
        background: url("https://oss.huizustore.com/90dba811b2c4450f8f650cc88eaaa72d.png");
        background-size: 100% 100%;
      }
      .open-active {
        background: url("https://oss.huizustore.com/8e1d2b8ccdb34371a978c1d8ab99f607.png");
        background-size: 100% 100%;
      }
          
      &-box {
        width: 13.25rem;
        height: 16.75rem;
        background: #FFF;
        border-radius: 12px;
        cursor: pointer;
        padding: 2.25rem;
        &-icon {
          width: 8.75rem;
          height: 8.75rem;
          margin: 0 auto;
        }
        &-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.75rem;
          color: #333333;
          line-height: 2.5rem;
          margin-top: 0.8rem;
        }
      }
    }
    #storeContent {
      background: url("https://oss.huizustore.com/d349fbdfbe4840a0ae336429246fbe4e.png");
      background-size: 100% 100%;
    }
    #appContent{
      background: url("https://oss.huizustore.com/4ca2cddefc064ebb858b08d2b4fdf545.png");
      background-size: 100% 100%;
    }
    #openContent{
      background: url("https://oss.huizustore.com/26a6dbabf39746ceab242ecb72b8afd5.png");
      background-size: 100% 100%;
    }
      
    .product-content-store{
      margin: 0 auto;
      margin-top: 2rem;
      width: 42.5rem;
      height: 27.75rem;
      background: #FFFFFF;
      border-radius: 10px;
      text-align: left;
      .product-content-title{
        display: inline-block;
        margin-top: 3.5rem;
        margin-left: 2.75rem;
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333;
      }
      .product-contents{
        margin-left: 2.75rem;
        li{
          margin-top: 1rem;
          font-size: 1.3rem;
          font-family: AppleSystemUIFont;
          color: #999;
          // line-height: 22px;
          display: flex;
          align-items: center;
          .orangeCircle{
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: red ;
            margin-right: 0.8rem;
          }
        }
      }
      .product-button{
        margin-top: 2rem;
        margin-left: 5.5rem;
        display: flex;
        column-gap: 1.2rem;
        .product-button-left{
          width: 12.5rem;
          height: 4rem;
          background: #ED0000;
          border-radius: 0.6rem;
          cursor: pointer;
          font-size: 1.5rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          a{
            color: #FFFFFF;
          }
        }
        .product-button-right{
          width: 12.5rem;
          height: 4rem;
          text-align: center;
          cursor: pointer;
          border-radius: 0.6rem;
          border: 1px solid #EB3A32;
          font-size: 1.5rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #EB3A32;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .product-rightBox{
        .rightStore{
          margin-top: -259px;
          // margin-right: 24px;
          float: right;
          width: 500px;
          height: 341px;
          // border: 1px dashed #000000;
          background-image: url('../../../官网切图/首页/租赁店铺.png');
          background-size: 100% 100%;
        }
        .rightApp{
          margin-top: -226px;
          // margin-right: 72px;
          float: right;
          width: 500px;
          height: 341px;
          // border: 1px dashed #000000;
          background-image: url('../../../官网切图/首页/小程序.png');
          background-size: 100% 100%;
        }
        .rightPc{
          margin-top: -226px;
          // margin-right: 0;
          float: right;
          width: 500px;
          height: 341px;
          // border: 1px dashed #000000;
          background-image: url('../../../官网切图/首页/PC独立站.png');
          background-size: 100% 100%;
        }
      }
    }
  }
  .guarantee{
    text-align: center;
    background: #FFF;
    padding-top: 4.5rem;
    padding-bottom: 5rem;
    height: 55rem;
    .guarantee-title{
      font-size: 27px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      line-height: 38px;
    }
    .hr-orange{
      width: 60px;
      height: 2px;
      background: #EF983B;
      border: 0;
    }
    .guarantee-title-bottom{
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393939;
      line-height: 30px;
    }
    .guarantee-content{
      padding-top: 2rem;
      width: 40rem;
      margin: 0 auto;
      display: flex;
      column-gap: 1.3rem;
      overflow: scroll;
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        height: 0;
      }
      .guarantee-item {
        width: 22rem;
        height: fit-content;
        border-radius: 0.67rem;
        background: #F9F8F8;
        &-img {
          width: 22rem;
          height: 15rem;
          border-radius: 12px 12px 0 0;
        }
        &-detail {
          padding: 1.2rem 1.3rem;
          text-align: start;
          &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 1.5rem;
            color: #333333;
            line-height: 1.8rem;
          }
          &-info {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.3rem;
            color: #999999;
            line-height: 2.1rem;
            margin-top: 1rem;
            height: 12.67rem;
          }
        }
      }
    }
  }
  .contact{
    margin: 0 auto;
    text-align: center;
    .contact-title{
      margin-top: 90px;
      font-size: 27px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #232323;
      line-height: 38px;
    }
    .contact-content{
      margin: 0 auto;
      margin-top: 57px;
      width: 1050px;
      height: 404px;
      background: #FFFFFF;
      box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
      border-radius: 8px;
      background-image: url("https://oss.huizustore.com/599238f6a6c141c08c7f5548ab0299b3.png");
      background-size: 100% 100%;
      position: relative;
      &-pop {
        position: absolute;
        width: 164px;
        top: 68px;
        left: 388px;
        text-align: start;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #111;
          line-height: 24px;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #393939;
          line-height: 22px;
        }
      }
      &-contact {
        position: absolute;
        left: 110px;
        bottom: 50px;
        &-icon {
          display: inline-block;
          width: 12px;
          height: 16px;
          background: url("https://oss.huizustore.com/8866b8d17691400ea2f9b3c16633b0fb.png");
          background-size: 100% 100%;
          margin-right: 8px;
        }
        &-phone {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 22px;
          color: #000000;
          line-height: 33px;
        }
        &-time {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 28px;
          margin: 0 8px;
        }
      }
      .contact-form{
        float: right;
        margin-right: 147px;
        text-align: left;
        .contact-form-title{
          margin-top: 30px;
          font-size: 21px;
          font-family: AppleSystemUIFont;
          font-weight: 500;
          color: #232323;
          line-height: 30px
        }
        .form-input{
          // margin-top: 30px;
          width: 255px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #979797;
          overflow: hidden;
          .input-style{
            border: 0px;
            outline: none;
            width: 100%;
            height: 100%;
          }
        }
        .form-button{
          margin-top: 10px;
          margin-left: 66px;
          width: 120px;
          height: 42px;
          background: #EB3A32;
          border-radius: 6px;
          font-size: 16px;
          font-family: AppleSystemUIFont;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          text-align: center;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
  .mobileContent-body-contact {
    overflow: hidden;
    background: #F9F8F8;
    .adm-form-item-feedback-error {
      margin-left: 3rem;
    }
    .adm-list{
      &-item {
        margin-top: 0!important;
        padding: 0;
        &-content {
          border-style: none;
          padding: 0;
          &-main {
            padding: 0;
            position: relative;
          }
        }
        &-description {
          margin-left: 19px;
          position: absolute;
          z-index: 1;
          div {
            margin-top: 0;
          }
        }
      }
      &-body {
        border-style: none;
      }
    }
    &-title {
      height: 2rem;
      font-size: 2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 2rem;
      margin-top: 4rem;
      text-align: center;                
    }
    &-input {
      width: 35rem;
      margin: 2rem auto 0;
      &-style {
        width: 338px;
        height: 43px;
        padding-left: 19px;
        border-radius: 7px;
        border: 1px solid #DDDDDD;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ABABAB;
        line-height: 25px;                        
      }
    }
    &-button {
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 2rem;
      width: 10rem;
      height: 4rem;
      background: #EB3A32;
      border-radius: 0.5rem;
      font-size: 2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 2rem;
      text-align: center;
      border-style: none;
      padding: 0;
      overflow: hidden;
    }
    &-step {
      width: 10.5rem;
      font-size: 1.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #333;
      line-height: 1.8rem;
      position: absolute;
      top: 1.5rem;
      left: 17.3rem;
      white-space: nowrap;
      div {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.17rem;
        color: #393939;
        line-height: 1.61rem;
      }
    }
    &-pic {
      width: 30rem;
      height: 23rem;
      margin-left: 50%;
      transform: translateX(-50%);
      background-image: url("https://oss.huizustore.com/42723ef3a684403b98b15b018bab5f53.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &-phone {
      display: flex;
      justify-content: center;
      margin: 1.2rem 0 4.4rem 0;
      &-icon {
        width: 1rem;
        height: 1.3rem;
        background-image: url(../../../官网切图/首页/联系电话.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &-phoneNumber {
        margin-left: 1rem;
        height: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.56rem;
        color: #000000;
        line-height: 2.22rem;
      }   
    }
  }
  .mobile-index-form {
    background-color: #F9F8F8 !important;
    --adm-color-background: #F9F8F8 !important;
  }
}

@primary-color: #0099ff;@font-size-base: 14px;