@import '../../../styles/global.less';

#lock-header {
  background: url("https://oss.huizustore.com/072844d549c84ce9ba12f740202706c9.png");
  background-size: 100% 100%;
}


#lock-service {
  .container {
    width: 1050px;
    height: 291px;
    display: flex;
    flex-wrap: nowrap;
    box-shadow: 2px 2px 23px rgba(0,0,0, 0.1);
    border-radius: 9px;
    margin: 0 auto;
  }
  img {
    width: 460px;
    height: 291px;
  }
  .item-box {
    height: 365px;
    text-align: start;
    padding: 63px 56px;
  }

  .item-btn {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    padding: 8px 24px;
    background-color: #ED0000;
    border-radius: 6px;
    display: inline-block;
    margin-top: 60px;
    cursor: pointer;
  }
  .item-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
  }
}

#service-strength {
  background-color: #F9F8F8;
  .container{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
  .strength-item {
    height: 183px;
    display: flex;
    text-align: start;
    flex-direction: column;
    width: 338px;
    padding: 33px 21px;
    background-color: #fff;
    border-radius: 9px;
    &-img {
      width: 30px;
      height: 27px;
      margin-bottom: 11px;
    }
    &-detail{
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        margin-bottom: 6px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 21px;
      }
    }
  }
}

#service-process {
  .container {
    display: flex;
    justify-content: center;
  }
  .process-item {
    width: 218px;
    height: 239px;
    text-align: start;
    margin-right: -18px;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 21px;
    }
  }
  #process-one {
    background: url("https://oss.huizustore.com/e07b9263668e4cd6b6b4a1bb83d148d1.png");
    background-size: 100% 100%;
    padding: 126px 0 0 17px;
  }
  #process-two {
    background: url("https://oss.huizustore.com/3aac7fafed644a4eb4fcc96b32b74d01.png");
    background-size: 100% 100%;
    padding: 126px 0 0 57px;
  }
  #process-three {
    background: url("https://oss.huizustore.com/7246844587044b4b914d7ea50657b412.png");
    background-size: 100% 100%;
    padding: 126px 0 0 57px;
  }
  #process-four {
    background: url("https://oss.huizustore.com/cef8bba0b35d454cac56381fdd1d83dd.png");
    background-size: 100% 100%;
    padding: 126px 0 0 57px;
  }
  #process-five {
    background: url("https://oss.huizustore.com/3e6cbff75b844c3a83ee0d2dfec95114.png");
    background-size: 100% 100%;
    padding: 126px 0 0 57px;
  }
}

#lock-online {
  background-color: #F9F8F8;
  
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.80rem;
    background-color: #FFFFFF;
    width: 1079px;
    height: 183px;
    margin: 0 auto;
  }
  .line {
    background: url("https://oss.huizustore.com/0ea1fc788e6040ea8e365d5d2a40c841.png");
    background-size: 100% 100%;
    width: 2px;
    height: 108px;
    margin-top: 41px;
  }
  #last {
    width: 349px;
  }
  .lock-item {
    padding: 60px 36px;
    width: 330px;
    height: 73px;
    position: relative;
    display: flex;
    text-align: start;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 21px;
      text-align: start;
      width: 216px;
      margin-right: 10px;
    }
    &-icon {
      margin-right: 15px;
    }
    #lock-img {
      width: 47px;
      height: 62px;
    }
    #unlock-img {
      width: 47px;
      height: 62px;
    }
    #free-img {
      width: 64px;
      height: 62px;
    }
  }

}

@primary-color: #0099ff;@font-size-base: 14px;