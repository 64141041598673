// @import '../../../index.css';

.mobileContent {
  width: 100%;

  #business-header {
    background: url("https://oss.huizustore.com/5f67133ed79d4bb2874d49f2d0713d2f.png");
    background-size: 100% 100%;
    width: 100%;
    height: 37.35rem;
    .mobileContent-container-header-top {
      margin-top: 3.65rem;
    }
  }

  .box {
    padding: 4.44rem 2.17rem;
    text-align: center;
    .box-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 2.33rem;
      color: #333333;
      line-height: 3.22rem;
      &::after {
        content: '';
        background-color: #ECCBA8;
        width: 3.33rem;
        height: 0.33rem;
        margin: 0 auto;
        display: block;
        margin-bottom: 1rem;
      }
    }
    .box-des {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.33rem;
      color: #666666;
      line-height: 1.83rem;
      margin: 2rem 0;
    }
  }

  #product {
    height: 55.22rem;
    width: 100%;
    background: url("https://oss.huizustore.com/a11031f6ab10475193b032f255383988.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    // padding: 0;
    .container {
      width: 90%;
      margin: 0 5%;
      position: absolute;
      bottom: 4.8rem;
      display: flex;
      gap: 1.9rem;
      justify-content: center;
      .data-data{
        border-radius: 50%;
        margin: 0 auto;
        .data-span{
          text-align: start;
          .data-span-font{
            font-family: Oswald ,DINCond, DINCond;
            font-weight: 900;
            font-size: 4.68rem;
            color: #ED0000;
            line-height: 4.82rem;
          }
          .data-span-unit {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.45rem;
            color: #ED0000;
            line-height: 1.99rem;
          }
        }
        .data-span1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.45rem;
          color: #333333;
          line-height: 1.99rem;
          text-align: start;
        }
      }
    }
  }
  #plat {
    height: 60.39rem;
    width: 100%;
    margin: 0 auto;
      background: url("https://oss.huizustore.com/b7ea884cb6e242369a698bc000432e76.png");
      background-size: 100% 100%;
    background-color: #F9F8F8;
    position: relative;
    // .box-header {
    //   padding: 0;
    //   height: 60.31rem;
    //   width: 38rem;
    // }
    .plat-content {
      position: absolute;
      bottom: 4.4rem;
      left: 50%;
      transform: translateX(-50%);
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.45rem;
      color: #333333;
      line-height: 1.99rem;
      white-space: nowrap;
      strong {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 3.61rem;
        color: #ED0000;
        line-height: 5.06rem;
        margin-left: 0.72rem;
      }
    }
  }
  #business {
    .container {
      display: flex;
      gap: 1.33rem;
      flex-wrap: wrap;
      justify-content: center;
    }
    .business-item {
      width: 18.17rem;
      height: 19.37rem;
      background-color: violet;
      position: relative;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.56rem;
        color: #FFFFFF;
        line-height: 2.22rem;
        padding: 8.94rem 0 0 2.06rem;
        text-align: start;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #FFFFFF;
        line-height: 2rem;
        width: 14.61rem;
        margin: 0.56rem 0 0 2.06rem;
        text-align: start;
      }
      &-tag {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.33rem;
        color: #FFFFFF;
        line-height: 1.56rem;
        padding: 0.56rem 1.06rem;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.67rem  0rem  0.67rem  0rem;
      }
    }
    .btn-item {
      width: 37.67rem;
      height: 18.89rem;
      border-radius: 0.67rem;
      padding: 2rem 0 0 1.89rem;
      background-color: #F9F8F8;
      text-align: start;
      position: relative;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.78rem;
        color: #333333;
        line-height: 2.5rem;
      }
      &-sub {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #666666;
        line-height: 2rem;
        margin: 0.67rem 0 0 0;
      }
      &-btn {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.56rem;
        color: #FFFFFF;
        line-height: 2.22rem;
        padding: 0.89rem 3.22rem;
        background-color: #ED0000;
        border-radius: 0.44rem;
        display: inline-block;
        position: absolute;
        bottom: 2.24rem;
        left: 1.89rem;
      }
    }
    #custom {
      background: url("https://oss.huizustore.com/d71dde559c3149f5bb2acdd6d0dbb9cd.png");
      background-size: 100% 100%;
    }
    #yajin {
      background: url("https://oss.huizustore.com/028756fea58244d587d45f7a97bf18bb.png");
      background-size: 100% 100%;
    }
    #zujin {
      background: url("https://oss.huizustore.com/95e07417707b434ebb7734af19b815aa.png");
      background-size: 100% 100%;
    }
    #zuwu {
      background: url("https://oss.huizustore.com/faaee0fc8d3945fda9e57137d308541e.png");
      background-size: 100% 100%;
    }
    #sifa {
      background: url("https://oss.huizustore.com/18dca83d52c24715898801d17c4da458.png");
      background-size: 100% 100%;
    }
    #shuju {
      background: url("https://oss.huizustore.com/106a42ea1079487186b8e86e51ed41ef.png");
      background-size: 100% 100%;
    }

    #blue {
      background-color: #1184F2;
    }
    #red {
      background-color: #ED0000;
    }
    #green {
      background-color: #00B493;
    }
  }

  #shop {
    background-color: #F9F8F8;
    .container{
      display: flex;
      flex-wrap: wrap;
      gap: 2.22rem;
      justify-content: center;
    }
    .shop-item {
      height: 5.89rem;
      display: flex;
      text-align: start;
      width: 33.98rem;
      margin-left: 3.89rem;
      &-img {
        width: 5.89rem;
        height: 5.89rem;
      }
      &-detail{
        padding: 0.61rem 2rem;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.78rem;
          color: #333333;
          line-height: 2.5rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.33rem;
          color: #666666;
          line-height: 1.83rem;
        }
      }
    }
  }

  #huizu {
    .container {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      justify-content: center;
      position: relative;
      .adm-swiper {
        --slide-size: 105% !important;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      &-item {
        display: flex;
        width: 20rem;
        height: 7rem;
        padding: 1.33rem 0 0 1.33rem;
        gap: 0.96rem;
        background-color: #F9F8F8;
        border-radius: 0.87rem;
        margin: 0 auto;
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.73rem;
          color: #333333;
          line-height: 2.47rem;
        }
        &-content {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.52rem;
          color: #666666;
          line-height: 2.17rem;
        }
      }
    }
    .icon-left {
      position: absolute;
      left: 1rem;
      height: 3rem;
      width: 3rem;
      background: url("https://oss.huizustore.com/2787cf1d4b57449f95d217926e933110.png");
      background-size: 100% 100%;
      z-index: 9;
    }
    .icon-right {
      position:absolute;
      right: 1rem;
      height: 3rem;
      width: 3rem;
      background: url("https://oss.huizustore.com/37585b770194408bbac6a9e64a525d1e.png");
      background-size: 100% 100%;
      z-index: 9;
    }
    .huizu-item {
      width: 33.13rem;
      text-align: start;
      margin: 0 auto;
      img {
        width: 33.13rem;
        height: 16.02rem;
      }
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.93rem;
        color: #333333;
        line-height: 2.71rem;
        display: inline-block;
        margin-right: 1rem;
      }
      &-tag {
        display: inline-flex;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.08rem;
        color: #333333;
        line-height: 1.45rem;
        gap: 0.48rem;
        vertical-align: 20%;
        &-item {
            padding: 0.18rem 0.36rem;
            border: 1px solid #666;
            background-color: #F9F8F8;
            border-radius: 0.28rem;
        }
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.33rem;
        color: #333333;
        line-height: 2.17rem;
        text-align: start;
        white-space: pre-wrap;
        width: 32.58rem;
      }
      &-count {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.27rem;
        color: #333333;
        line-height: 1.75rem;
        display: flex;
        gap: 1.75rem;
        strong {
          font-family: Oswald, DINCond, DINCond;
          font-weight: 900;
          font-size: 4.22rem;
          color: #333333;
          line-height: 4.22rem;
        }
      }
    }
  }
  #comit {
    background-color: #F9F8F8;
    .img-box {
      width: 11.56rem;
      height: 19rem;
      background: url("https://oss.huizustore.com/e713b1d63f3c4f18b8db3408df1f8fbc.png");
      background-size: 100% 100%;
      position: relative;
      margin: 0 auto;
      .img {
        width: 100%;
        object-fit: fill;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.56rem;
      color: #393939;
      line-height: 2.22rem;
    }
    .sub {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.33rem;
      color: #393939;
      line-height: 1.83rem;
    }
    .btn {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.56rem;
      color: #FFFFFF;
      line-height: 2.22rem;
      padding: 0.89rem 3.22rem;
      background-color: #ED0000;
      border-radius: 0.44rem;
      display: inline-block;
      margin-top: 0.78rem;
    }
  }
}
.adm-toast-main {
  z-index: 9999 !important;
}
.mobile-business-modal {
  z-index: 1 !important;
  .adm-form-item-child-inner {
    display: flex;
    span {
      white-space: nowrap;
      // width: 25rem;
      text-align: end;
      display: flex;
      align-items: center;
      justify-content: end;
      font-weight: 500;
    }
    input {
    }
  }
  .form_line {
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
    }
  }
  // .adm-form-item-required-asterisk {
  //   display: none !important;
  // }
  // .adm-list-item-content-prefix {
  //   display: flex !important;
  //   justify-content: end !important;
  //   padding-right: 0 !important;
  // }
  // .adm-form-item-label {
  //   display: flex !important;
  //   align-items: center !important;
  //   justify-content: flex-end !important;
  //   font-weight: 500 !important;
  //   #text {
  //     width: 100%;
  //   }
  // }
  .adm-input {
    padding: 0.6rem 0.9rem;
    border: 0.05rem solid #E3E3E3;
    border-radius: 0.25rem;
  }
  .adm-center-popup-body {
    background-color: #FFF !important;
    border-radius: 0.8rem;
  }
  .adm-center-popup-wrap {
    width: 80%;
  }
  .adm-form-item-feedback-error {
    text-align: start;
  }
  .ask-img {
    width: 22.7rem;
    height: 22.7rem;
  }
  .ask-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 1.45rem;
    color: #282828;
    line-height: 1.99rem;
  }
  &-content {
    text-align: center;
    position: relative;
    .close {
      width: 1.45rem;
      height: 1.45rem;
      position: absolute;
      top: 0;
      right: 0;
      background: url("https://oss.huizustore.com/caac6e2b6049464095bf54b4ebbfdcb2.png");
      background-size: 100% 100%;
          }
    strong {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.93rem;
      color: #333333;
      line-height: 2.71rem;
      display: block;
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;