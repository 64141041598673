//Pc
.pcContent,
.abboutPage {
  min-width: 1440px;
  position: relative;
  .swiper-dots {
    li {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #E8E8E8;
      button {
        width: 5px;
        height: 5px;
        background-color: #E8E8E8;
        border-radius: 50%;
      }
    }
    .slick-active {
      width: 5px !important;
      height: 5px;
      border-radius: 50%;
      background-color: #ED0000;
      button {
        width: 5px;
        height: 5px;
        background-color: #ED0000 !important;
        border-radius: 50%;
      }
    }
  }
  &-container {
    width:100%;
    background-color: #F9F8F8;
    &-header {
      width: 1440px;
      height: 423px;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      .container {
        margin-top: 127px;
        margin-left: 149px;
      }
      &-top {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 35px;
        color: #333333;
        line-height: 49px;
      }
      &-center {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
      }
      &-bottom {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 21px;
        color: #FFFFFF;
        line-height: 30px;
        padding: 10px 60px;
        background-color: #ED0000;
        border-radius: 6px;
        display: inline-block;
        margin-top: 2.5rem;
        cursor: pointer;
        a {
        color: #FFFFFF;
        }
      }
    }
  }

  // 专题
  .box {
    padding: 90px 120px;
    text-align: center;
    .box-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 44px;
      &::after {
        content: '';
        background-color: #ECCBA8;
        width: 45px;
        height: 5px;
        margin: 0 auto;
        display: block;
        margin-bottom: 14px;
      }
    }
    .box-des {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin-bottom: 45px;
    }
  }
}

.modalInquiry{
  padding: 0;
  .ant-modal-content{
    width: 600px;
    height: 498px;
    background: #fff;
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 54px;
    }
    img {
      margin: 0 auto;
      display: block;
    }
    .content{
      text-align: center;
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      padding-bottom: 60px;
    }
  }
}

// .modalAppointment{
//   backdrop-filter: blur(10px);
//   .ant-modal-content{
//     width: 570px;
//     height: 417px;
//     // background: #FFFFFF;
//     border-radius: 8px;
//     .title{
//       text-align: center;
//       font-size: 27px;
//       font-family: SourceHanSansCN-Medium, SourceHanSansCN;
//       font-weight: 500;
//       color: #232323;
//       line-height: 54px;
//     }
//     .form-div{
//       width: 360px;
//       margin-left: 88.5px;
//       margin-top: 30px;
//       font-size: 18px;
//       font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//       font-weight: 400;
//       color: #232323;
//       height: 36px;
//       // line-height: 36px;
//       .span{
//         float: left;
//         margin-top: 6px;
//         display: block;
//         height: 25px;
//         line-height: 25px;
//       }
//       .inputs{
//         float: left;
//         display: block;
//         width: 255px;
//         height: 36px;
//         border-radius: 6px;
//         border: 1px solid #979797;
//         overflow: hidden;
//         .input{
//           outline: none;
//           border: 0px;
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }
//     .buttonBox{
//       .button{
//         margin-left: 201px;
//         margin-top: 24px;
//         width: 120px;
//         height: 42px;
//         background: #EB3A32;
//         border-radius: 8px;
//         line-height: 34px;
//         font-size: 18px;
//         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//         font-weight: 400;
//         color: #FFFFFF;
//         text-align: center;
//         border: none;
//         cursor: pointer;
//       }
//     }

//   }
// }