.headerNews {
  position: absolute;
  top: 14px;
  right: 195px;
  z-index: 1;
  &-top {
    width: 213px;
    height: 158px;
    background: #fff;
    border-radius: 9px;
    &-img-box {
      display: flex;
      justify-content: center;
      gap: 24px;
      padding: 8px 0;
      border-bottom: 1px solid #F5F5F5;
    }
    &-img-item {
      img {
        width: 68px;
        height: 68px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #ED0000;
        line-height: 15px;
        text-align: center;
      }
    }

    &-content-box {
      padding: 10px 15px;
      display: flex;
      gap: 9px;
      img {
        width: 35px;
        height: 35px;
      }
      .telephone,
      .time {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #ABABAB;
        line-height: 15px;
        white-space: nowrap;
      }
    }
  }

  &-footer {
    margin-top: 12px;
    background: #fff;
    border-radius: 9px;
    &-content-box {
      padding: 13px;
      border-bottom: 1px solid #F5F5F5;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 11px;
        color: #333333;
        line-height: 15px;
        margin-bottom: 8px;
      }
      .news-item {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 6px;
        #title {
          width: 150px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        span {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 11px;
          color: #999999;
          line-height: 15px;
        }
      }
    }
    &-more {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 11px;
      color: #ED0000;
      line-height: 15px;
      padding: 14px 0;
      cursor: pointer;
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;