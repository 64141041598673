@import '../../../styles/global.less';

#program-header {
  background: url("https://oss.huizustore.com/65ef1d3fef4e45709f27aa44359ed8fc.png");
  background-size: 100% 100%;
}

#zero-program {
  background: #F9F8F8;
  img {
    width: 1050px;
    height: 567px;
  }
  .content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    margin-top: 32px;
    strong {
      font-family: Oswald, DINCond, DINCond;
      font-weight: 900;
      font-size: 38px;
      color: #ED0000;
      line-height: 46px;
      span {
        font-family: AlibabaPuHuiTiR;
        font-size: 15px;
        color: #ED0000;
        line-height: 18px;
        vertical-align: middle;
      }
    }
  }
}

#icon-program {
  .container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 150px;
    row-gap: 80px;
    justify-content: center;
    margin: 0 auto;
    margin-top: 80px;
    width: 1000px;
  }
  .icon-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80px;
    &-img {
      width: 64px;
      height: 56px;
      margin-bottom: 15px;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      white-space: nowrap;
    }
  }
}

#solve-program {
  .container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    width: 1050px;
    margin: 0 auto;
  }
  .solve-item {
    display: flex;
    text-align: start;
    background-color: #F9F8F8;
    border-radius: 9px;
    width: 519px;
    height: 149px;
    &-img {
      width: 149px;
      height: 149px;
    }
    &-detail{
      padding: 34px 22px;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 21px;
      }
    }
  }
}

#ope-program {
  background: #F9F8F8;
  .container {
    display: flex;
    gap: 18px;
    margin:  0 auto;
    justify-content: center;
  }
  .ope-item {
    background-color: #fff;
    text-align: start;
    border-radius: 9px;
    width: 338px;
    height: 360px;
    &-img {
      width: 338px;
      height: 201px;
    }
    &-detail {
      padding: 26px 20px;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 21px;
      }
    }
  }
}

@primary-color: #0099ff;@font-size-base: 14px;