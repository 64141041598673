.about {
    #huizu {
        // padding-top: 0;
        .container {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-top: 2rem;
        }
        .huizu-item {
          width: 19.88rem;
          height: 28.31rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 19.88rem;
            height: 28.31rem;
          }
          #small {
            width: 19.88rem;
            height: 13.73rem;
          }
        }
      }


    &-container {
        width:100%;
        text-align: center;
        &-header {
            width:100%;
            height: 34.5rem;
            position: relative;
            overflow: hidden;
            background: url("https://oss.huizustore.com/d4855e2f1bea428186b951554a5f1240.png");
            background-size: 100% 100%;
            padding-top: 16rem;
            &-top {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 2.56rem;
                color: #333333;
                line-height: 3.61rem;
            }
            &-center {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 1.33rem;
                color: #666666;
                line-height: 1.83rem;
            }
            &-bottom {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 1.67rem;
                color: #FFFFFF;
                line-height: 2.33rem;
                padding: 1rem 4.2rem;
                background-color: #ED0000;
                border-radius: 0.67rem;
                display: inline-block;
                margin-top: 2.5rem;
                a {
                    color: #E5E5E5;
                }
            }
        }
    }
    &-main {
        text-align: center;
        padding: 4.44rem 0;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 2.33rem;
            color: #333333;
            line-height: 3.22rem;
            &::after {
                content: '';
                background-color: #ECCBA8;
                width: 3.33rem;
                height: 0.33rem;
                margin: 0 auto;
                display: block;
                margin-bottom: 1rem;
            }
        }
        &_sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 1.83rem;
        }
        &-img {
            width: 37.67rem;
            height: 20.17rem;
            margin-top: 2.22rem;
        }
        &-content {
            width: 35.33rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 2rem;
            margin: 2.22rem auto;
            text-align: start;
        }
    }
    &-team {
        text-align: center;
        background-color: #F9F8F8;
        padding: 4.44rem 0;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 2.33rem;
            color: #333333;
            line-height: 3.22rem;
            &::after {
                content: '';
                background-color: #ECCBA8;
                width: 3.33rem;
                height: 0.33rem;
                margin: 0 auto;
                display: block;
                margin-bottom: 1rem;
            }
        }
        &-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 1.83rem;
        }
        &-member {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 2rem;
            margin-top: 3.39rem;
            &-item {
                display: flex;
                &-img {
                    width: 12.11rem;
                    height: 15.11rem;
                }
                &-content {
                    width: 25.22rem;
                    height: 12.06rem;
                    background: url("https://oss.huizustore.com/e307fd809fe54bb6b1068f09a85bdb1a.png");
                    background-size: 100% 100%;
                    align-self: flex-end;
                    text-align: start;
                    padding: 1.72rem 0 0 2.5rem;
                    &-title {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 1.56rem;
                        color: #333333;
                        line-height: 2.22rem;
                    }
                    &-sub {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 1.17rem;
                        color: #666666;
                        line-height: 1.61rem;
                    }
                    &-content {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 1.17rem;
                        color: #666666;
                        line-height: 1.67rem;
                        margin-top: 1.17rem;
                    }
                }
            }
        }
    }
    &-trial {
        padding: 4.44rem 0;
        text-align: center;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 2.33rem;
            color: #333333;
            line-height: 3.22rem;
            &::after {
                content: '';
                background-color: #ECCBA8;
                width: 3.33rem;
                height: 0.33rem;
                margin: 0 auto;
                display: block;
                margin-bottom: 1rem;
            }
        }
        &-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 1.83rem;
            margin-top: 2rem;
        }
    }
    &-huizu {
        padding: 4.44rem 0;
        text-align: center;
        background-color: #F9F8F8;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 2.33rem;
            color: #333333;
            line-height: 3.22rem;
            &::after {
                content: '';
                background-color: #ECCBA8;
                width: 3.33rem;
                height: 0.33rem;
                margin: 0 auto;
                display: block;
                margin-bottom: 1rem;
            }
        }
        &-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 1.83rem;
            margin-top: 2rem;
        }
        &-content {
            margin: 0 auto;
            text-align: start;
            width: 37.89rem;
            &-img {
                width: 37.89rem;
                height: 34.89rem;

            }
            &-title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 1.78rem;
                color: #333333;
                line-height: 2.5rem;
                margin-top: 2.2rem;
            }
            &-content {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 1.33rem;
                color: #666666;
                line-height: 2.11rem;
            }
        }
    }
    &-value {
        padding: 4.44rem 0;
        text-align: center;
        &-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 2.33rem;
            color: #333333;
            line-height: 3.22rem;
            &::after {
                content: '';
                background-color: #ECCBA8;
                width: 3.33rem;
                height: 0.33rem;
                margin: 0 auto;
                display: block;
                margin-bottom: 1rem;
            }
        }
        &-sub {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 1.33rem;
            color: #666666;
            line-height: 1.83rem;
            margin-top: 2rem;
        }
        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1.33rem;
            margin-top: 2.22rem;
            &-item {
                width: 18.17rem;
                height: 8.56rem;
                background-color: #F9F8F8;
                border-radius: 0.67rem;
                text-align: start;
                padding: 1.33rem 0 0 1.33rem;
                &-title {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 1.33rem;
                    color: #333333;
                    line-height: 1.83rem;
                }
                &-content {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 1.33rem;
                    color: #666666;
                    line-height: 2.11rem;
                    margin-top: 0.33rem;
                }
            }
        }
    }
    
    .modalAppointment{
        .ant-modal-content{
            border-radius: 12px;
            .ant-modal-body{
                .title{
                    text-align: center;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                }
                .buttonBox{
                    text-align: center;
                    .button{
                        border-radius: 6px;
                        font-size: 17px;
                        background-color: red;
                        color:#FFFFFF
                    }
                }
            }
        }
    }
    
}


@primary-color: #0099ff;@font-size-base: 14px;