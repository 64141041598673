@import '../../../styles/global.less';

#business-header {
  background: url("https://oss.huizustore.com/e9bb0a5470d4433c8f54d42f684b407b.png");
  background-size: 100% 100%;
}

.product-feat {
  &-header {
    position: relative;
    width: 1440px;
    height: 542px;
    background: url("https://oss.huizustore.com/50f49551fdd24c6da96983964d6ead61.png");
    background-size: 100% 100%;
    margin: 0 auto;
  }
  &-detail {
    position: absolute;
    top: 90px;
    left: 280px;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 44px;
    }
    &-sub {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin: 21px 0 37px 0;
    }
    &-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 44px;
      width: 380px;
      .data-data{
        border-radius: 50%;
        .data-span{
          text-align: start;
          .data-span-font{
            font-family: Oswald, DINCond, DINCond;
            font-weight: 900;
            font-size: 60px;
            color: #ED0000;
            line-height: 60px;
          }
          .data-span-unit {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #ED0000;
            line-height: 25px;
          }
        }
        .data-span1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          text-align: start;
        }
      }
    }
  }
}


#plat-pc {
  width: 100%;
  padding: 30px 0;
  .plat-pc-header {
    margin-top: -45px;
    width: 1440px;
    height: 638px;
    background: url("https://oss.huizustore.com/61f9e0f801be4938a7ea864c19729312.png");
    background-size: 100% 100%;
    margin: 0 auto;
    .container {
      transform: translateY(30px);
    }
  }
}


#business-pc {
  background-color: #F9F8F8;
  .container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    width: 1080px;
    margin: 0 auto;
  }
  .business-small-item {
    transition: all 0.2s;
    width: 131px;
    height: 456px;
    position: relative;
    .business-item-title {
      padding: 0;
      padding-top: 383px;
      text-align: center;
      // display: inline-block;
    }
  }
  .business-item {
    transition: all 0.2s;
    width: 338px;
    height: 456px;
    position: relative;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 21px;
      color: #FFFFFF;
      line-height: 30px;
      padding: 316px 0 0 21px;
      text-align: start;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 23px;
      width: 150px;
      margin: 15px 0 0 21px;
      text-align: start;
    }
    &-tag {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.33rem;
      color: #FFFFFF;
      line-height: 1.56rem;
      padding: 0.56rem 1.06rem;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.67rem  0rem  0.67rem  0rem;
    }
  }
  .btn-item {
    width: 519px;
    height: 149px;
    border-radius: 9px;
    padding: 34px 0 0 28px;
    background-color: #fff;
    text-align: start;
    position: relative;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    &-sub {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 21px;
      width: 299px;
    }
    &-btn {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
      padding: 8px 24px;
      background-color: #ED0000;
      border-radius: 6px;
      display: inline-block;
      position: absolute;
      top: 62px;
      right: 51px;
      cursor: pointer;
    }
  }
  #custom {
    background: url("https://oss.huizustore.com/8a77a61e22744f0dab8c6a55595b3f75.png");
    background-size: 100% 100%;
  }
  #custom-small {
    background: url("https://oss.huizustore.com/4b6a085b937b4d1285574998c8f54097.png");
    background-size: 100% 100%;
  }
  #yajin {
    background: url("https://oss.huizustore.com/6081069ae37a4007b29f1db7d01dd85b.png");
    background-size: 100% 100%;
  }
  #yajin-small {
    background: url("https://oss.huizustore.com/20a7c1e47d0d4226861cbf43ac7cec93.png");
    background-size: 100% 100%;
  }
  #zujin {
    background: url("https://oss.huizustore.com/bcdc9bfe01234b62ac27347b02e9237f.png");
    background-size: 100% 100%;
  }
  #zujin-small {
    background: url(https://oss.huizustore.com/221f399538c441a98bce319c4593f8e6.png);
    background-size: 100% 100%;
  }
  #zuwu {
    background: url("https://oss.huizustore.com/cee1702f3d324602a3f2e4f2240b3c85.png");
    background-size: 100% 100%;
  }
  #zuwu-small {
    background: url("https://oss.huizustore.com/a29cffe55efe4ebd9125ae91e507c654.png");
    background-size: 100% 100%;
  }
  #sifa {
    background: url("https://oss.huizustore.com/a6409d896e71421582249919b485166f.png");
    background-size: 100% 100%;
  }
  #sifa-small {
    background: url("https://oss.huizustore.com/f7c9a43eccab4b3396bba0a7c3ee94c0.png");
    background-size: 100% 100%;
  }
  #shuju {
    background: url("https://oss.huizustore.com/ddaddf755de14501973e2d58c6897595.png");
    background-size: 100% 100%;
  }
  #shuju-small {
    background: url("https://oss.huizustore.com/397908a6864c4f94ba32489a424d6db1.png");
    background-size: 100% 100%;
  }

  #blue {
    background-color: #1184F2;
  }
  #red {
    background-color: #ED0000;
  }
  #green {
    background-color: #00B493;
  }
}

#shop-pc {
  .container{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
  .shop-item {
    height: 227px;
    display: flex;
    text-align: start;
    flex-direction: column;
    width: 254px;
    padding: 32px 0 0 29px;
    background-color: #F9F8F8;
    border-radius: 9px;
    &-img {
      width: 80px;
      height: 80px;
      margin-bottom: 19px;
    }
    &-detail{
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
      }
    }
  }
}

#huizu-pc {
  background-color: #F9F8F8;
  .container {
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: center;
  }
  .list {
    width: 970px;
    margin: 0 auto;
    .slick-track {
      display: flex !important;
      gap: 8px;
    }
    .slick-arrow {
      display: none !important;
    }
    .slider-container {
      width: 970px;
      overflow: hidden;
    }
    .slick-list {
      margin: 0 -50px !important;
      overflow: hidden;
      width: 1050px;
    }
    &-item {
      background-color: #fff;
      border-radius: 8px;
      display: flex !important; 
      padding: 17px;
      gap: 9px;
      text-align: start;
      width: 200px;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #666666;
        line-height: 15px;
      }
    }
  }
  .icon-left {
    width: 45px;
    height: 45px;
    background: url("https://oss.huizustore.com/d04017e27e6b47118c1fb46386a9aa9c.png");
    background-size: 100% 100%;
  }
  .icon-right {
    width: 45px;
    height: 45px;
    background: url("https://oss.huizustore.com/6cb223d8bf4342209a60c91472c209c8.png");
    background-size: 100% 100%;
  }
  .ant-carousel {
    width: 1050px;
  }
  .huizu-item {
    display: flex !important;
    gap: 49px;
    .detail {
      text-align: start;
      padding: 28px 0;
    }
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #333333;
      line-height: 34px;
    }
    &-tag {
      margin: 16px 0;
      display: flex;
      gap: 8px;
      &-item {
        padding: 2px 5px;
        border: 1px solid #333;
        border-radius: 8px;
      }
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      line-height: 20px;
      width: 646px;
    }
    &-count {
      margin: 16px 0;
      display: flex;
      gap: 36px;
      div {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
        line-height: 17px;
      }
      strong {
        font-family: Oswald, DINCond, DINCond;
        font-weight: 900;
        font-size: 53px;
        color: #333333;
        line-height: 53px;
      }
    }
  }
}

#comit-pc {
  .box-title {
    margin-bottom: 45px;
    &::after {
      display: none;
    }
  }
  .container {
    width: 1049px;
    height: 302px;
    background: url("https://oss.huizustore.com/139ecd358a8b4a94be81a8108dc9576d.png");
    background-size: 100% 100%;
    position: relative;
    margin: 0 auto;
  }
  .content-box {
    width: 180px;
    height: 120px;
    position: absolute;
    top: 95px;
    left: 411px;
    margin: 0 auto;
    text-align: start;
  }
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #393939;
    line-height: 25px;
  }
  .sub {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #393939;
    line-height: 22px;
    margin-top: 6px;
  }
  .btn {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 27px;
    padding: 8px 24px;
    background-color: #ED0000;
    border-radius: 6px;
    display: inline-block;
    margin-top: 26px;
    cursor: pointer;
  }
  .img {
    position: absolute;
    top: 74px;
    right: 218px;
    width: 156px;
    height: 156px;
  }
}

@primary-color: #0099ff;@font-size-base: 14px;