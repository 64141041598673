.fixBox{
  z-index: 2;
  top: 100px;
  right: 12px;
  height: 300px;
  position: fixed;
  .box-right{
    .top{
      width: 84px;
      height: 91px;
      background-image: url("https://oss.huizustore.com/11d7962903c14ef387a52fa33e85eb4b.png");
      background-size: 100% 100%;
    }
    .box{
      padding: 0;
      margin-top: -20px;
      margin-left: 9px;
      width: 66px;
      height: 21px;
      border-radius: 3px;
      .tip{
        text-align: center;
        line-height: 21px;
        color: #ffffff;
        font-size: 12px;
      }
    }
    .lease{
      margin-top: 6px;
      width: 78px;
      height: 78px;
      background: red;
      border-radius: 5px 5px 0 0;
      padding-top: 15px;
      margin-left: 4px;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url("https://oss.huizustore.com/0d84ebd1024242289c72a93e4ecd967d.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      .box-lease{
        display: none;
        position: fixed;
        right: 72px;
        top: 136px;
        width: 387px;
        height: 437px;
        background: url("https://oss.huizustore.com/cc55acda5aaa4486b4347f1cc41b8c39.png");
        border-radius: 8px;
        text-align: center;
        .title{
          text-align: center;
          margin-top: 48px;
          font-size: 27px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
          line-height: 38px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin: 0 auto;
          margin-top: 18px;
          width: 231px;
          height: 231px;
          background-image: url("https://oss.huizustore.com/c44b255b70f84f4d85c0fad047b54f68.png");
          background-size: 100% 100%;
        }
        .tip{
          margin-top: 18px;
          height: 30px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
          line-height: 30px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          text-align: center;
        }
      }
      cursor: pointer;
      &:hover{
        background: red;
        .box-lease{
          display: block;
        }
      }
    }
    .switch{
      margin-bottom: -156px;
      width: 78px;
      height: 156px;
      background: red;
      box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
      border-radius: 0 0 5px 5px;
      backdrop-filter: blur(6px);
      margin-left: 4px;
    }
    .wechat{
      position: relative;
      width: 78px;
      height: 78px;
      // background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      padding-top: 15px;
      z-index: 1;
      margin-left: 4px;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url("https://oss.huizustore.com/f543c09afbdf43e383cacd0290c2bd7b.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      cursor: pointer;
      &:hover{
        background: red;
        .box-wechat{
          display: block;
        }
      }
      .box-wechat{
        display: none;
        position: fixed;
        right: 72px;
        top: 72px;
        width: 387px;
        height: 478px;
        background: url("https://oss.huizustore.com/24f41177ff604f26bbbaf0826e36be3a.png");
        border-radius: 8px;
        .title{
          text-align: center;
          margin-top: 48px;
          height: 38px;
          font-size: 27px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
          line-height: 38px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .tip{
          text-align: center;
          height: 17px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393939;
          line-height: 17px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin: 0 auto;
          margin-top: 18px;
          width: 231px;
          height: 231px;
          background-image: url("https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png");
          background-size: 100% 100%;
        }
        .content{
          margin-top: 18px;
          text-align: center;
          height: 30px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
          line-height: 30px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
      }
    }
    .phone{
      position: relative;
      z-index: 1;
      width: 78px;
      height: 78px;
      border-radius: 5px;
      padding-top: 15px;
      margin-left: 4px;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url("https://oss.huizustore.com/6d2303eef0f6446b99b036941e4b4725.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      &:hover{
        background: red;
        .box-phone{
          display: block;
        }
      }
      cursor: pointer;
      .box-phone{
        display: none;
        position: fixed;
        right: 78px;
        top: 284px;
        width: 347px;
        height: 194px;
        background: url("https://oss.huizustore.com/65679e09572c44c48c1196430d623255.png");
        background-size: 100% 100%;
        padding: 39px 0 0 71px;
        .top1{
          font-size: 13.5px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #232323;
          line-height: 48px;
          padding-top: 30px;
          .big{
            font-size: 24px;
            margin-left: 30px;
          }
        }
        &-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 32px;
          color: #333;
          line-height: 45px;
        }
        &-phone {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          white-space: nowrap;
        }
        &-time {
          font-family: PingFang-SC, PingFang-SC;
          font-weight: 500;
          font-size: 21px;
          color: #666;
          line-height: 29px;
          white-space: nowrap;
        }
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;