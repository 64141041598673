body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .adm-text-area{
  --placeholder-color: red !important; 
} */
/* .adm-modal-content{
  display: none;
} */

.adm-modal-footer{
  display: none !important;
}

.adm-modal{
  z-index: 99999 !important;
}

html {
  font-size: 100% !important;
}

@media (max-width: 850px) {
  html {
    font-size: 80% !important;
  }
}
@media (max-width: 650px) {
  html {
    font-size: 75% !important;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 70% !important;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 65% !important;
  }
}
@media (max-width: 500px) {
  html {
    font-size: 60% !important;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 55% !important;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 50% !important;
  }
}
@media (max-width: 375px) {
  html {
    font-size: 45% !important;
  }
}
@media (max-width: 350px) {
  html {
    font-size: 42.5% !important;
  }
}
@media (max-width: 325px) {
  html {
    font-size: 40% !important;
  }
}
