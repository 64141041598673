@import '../../../styles/global.less';

#platform-header {
  background: url("https://oss.huizustore.com/b7d14428e47a413d9cfd2ecd4a9aebbd.png");
  background-size: 100% 100%;
  .container {
    margin-top: 69px;
  }
  .pcContent-container-header-bottom {
    margin-top: 53px;
  }
}

#wind-platform {

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
  .wind-item {
    padding: 34px 28px;
    background-color: #F9F8F8;
    width: 519px;
    height: 149px;
    position: relative;
    text-align: start;
    border-radius: 9px;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 21px;
      width: 299px;
      margin-top: 8px;
    }
    &-btn {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
      padding: 8px 24px;
      background-color: #ED0000;
      white-space: nowrap;
      border-radius: 6px;
      display: inline-block;
      position: absolute;
      bottom: 50px;
      right: 51px;
      cursor: pointer;
    }
  }
}

#lock-platform {
  background: #F9F8F8;
  .container {
    display: flex;
    gap: 18px;
    margin:  0 auto;
    justify-content: center;
  }
  .lock-item {
    background-color: #fff;
    text-align: start;
    border-radius: 9;
    width: 338px;
    height: 360px;
    &-img {
      width: 338px;
      height: 201px;
    }
    &-detail {
      padding: 26px 20px;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }
      &-content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 21px;
      }
    }
  }
}

#more-platform {
  .container {
    width: 1050px;
    height: 364px;
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
    box-shadow: 2px 2px 23px rgba(0,0,0, 0.1);
    border-radius: 9px;
    margin: 0 auto;
  }
  .more-img {
    width: 460px;
    height: 365px;
  }
  .item-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 365px;
  }
  .more-item {
    padding: 32px 0;
    text-align: start;
    border-bottom: 1px solid #E0E0E0;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }
    &-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 21px;
    }
  }
  #none-border {
    border: none;
  }
}

@primary-color: #0099ff;@font-size-base: 14px;