.news {
    min-width: 1440px;
    background: #F6F6F6;
    #news-header {
        background: url("https://oss.huizustore.com/fa4dd4fbe2484acebcdedb1fa08b3af1.png");
        background-size: 100% 100%; 
        text-align: center;
        .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 35px;
            color: #333333;
            line-height: 49px;
            margin-top: 98px;
            margin-bottom: 12px;
        }
        .input-box {
            width: 483px;
            height: 50px;
            background: #fff;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 14px 26px;
            .icon {
                background: url("https://oss.huizustore.com/164fdd0b192d465e8127c0c91fdaa2b7.png");
                width: 23px;
                height: 23px;
                background-size: 100% 100%;
            }
        }
    }
    #news-container {
        background: linear-gradient(180deg, #FFEDED 0%, #F9F8F8 100% );
    }
    .news_content {
        display: flex;
        column-gap: 18px;
        margin-top: -123px;
        padding: 0 40px;
        justify-content: center;
        position: relative;
        z-index: 2;
        &_left {
            width: 735px;
            border-radius: 12px;
            background-color: #fff;
            &_title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                line-height: 28px;
                padding: 18px;
                border-bottom: 2px solid #F5F5F5;
            }
        }
        &_right {
            width: 297px;
            display: flex;
            flex-direction: column;
            row-gap: 18px;
            &_news {
                width: 297px;
                padding: 18px;
                background-color: #fff;
                border-radius: 12px;
                &_title {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 18px;
                    color: #333333;
                    line-height: 21px;
                    margin-bottom: 16px;
                }
                ul {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    line-height: 18px;
                    padding-left: 24px;
                }
                li {
                    margin-bottom: 16px;
                    cursor: pointer;
                    &::marker {
                        color: #ED0000;
                    }
                }
            }
            &_tags {
                width: 297px;
                padding: 18px;
                padding-right: 0;
                background-color: #fff;
                border-radius: 12px;
                &_title {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 18px;
                    color: #333333;
                    line-height: 21px;
                    margin-bottom: 13px;
                }
                &_list {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 9px;
                    row-gap: 16px;
                    .tag_item {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #ED0000;
                        line-height: 17px;
                        padding: 2px 9px;
                        background-color: rgba(#ED0000, 5%);
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
    .newsItem {
        width: 735px;
        height: 154px;
        padding: 18px;
        display: flex;
        column-gap: 16px;
        &:hover {
            position: relative;
            box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.02);
            z-index: 2;
        }
        &_img {
            width: 182px;
            height: 120px;
        }
        &_detail {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &_type {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 17px;
                padding: 1px 5px;
                background-color: #ED0000;
                margin-right: 9px;
            }
            &_title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 17px;
                color: #333333;
                line-height: 17px;
                width: 367px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            &_time {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 14px;
            }
            &_tag {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                
            }
        }
    }
    .newsPage {
        display: inline-block;
        margin: 0 0 0 50%;
        transform: translate(-50%, 54px);
        margin-bottom: 150px;
        .ant-pagination-item {
            width: 54px;
            height: 54px;
            background: unset;
            border: 0px;
            // box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
            border-radius: 100%;
            a {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #282828;
                line-height: 54px;
            }
        }
        .ant-pagination-item-active {
            box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
            background: #FFFFFF;
            a {
                color: #585858;
            }
        }
        .ant-pagination-prev {
            .ant-pagination-item-link {
                border: 0px;
                background: unset;
                path {
                    d:path("M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z")!important
                }
            }
        }
        .ant-pagination-next {
            .ant-pagination-item-link {
                border: 0px;
                background: unset;
                path {
                    d:path("M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z")!important
                }
            }
        }
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 48px;
    }    
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        margin: 0 auto;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
        border: 0px;
    }
    .ant-tabs-top .ant-tabs-ink-bar-animated {
        height: 3px;
        background: #EB3A32;
    }
    .ant-tabs-tab {
        padding: 0;
    }
    .ant-tabs-tab-btn {
        font-size: 27px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 38px;
        padding: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #282828;
        line-height: 38px;
        padding: 0;
    }
}
@primary-color: #0099ff;@font-size-base: 14px;